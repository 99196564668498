<app-loader [size]="150" [stripeWidth]="10" [theme]="'white'" [showLoader]="showLoader"></app-loader>

<app-lightbox id="rbForgottenYourUsername" *ngIf="openedModals?.rbForgottenYourUsername"
  (closeLightboxEvent)="closeModal('rbForgottenYourUsername')" [modalOutside]="true"
  [focusElement]="'rb-forgot-username-focus-el'" [lastActivatedElement]="'rbwm-forget-password'"
  [identification]="'rbForgottenYourUsername'" [xButton]="true">
  <div class="instructions-box">
    <h2 class="mns-h2-modal" id="dialogTitle" tabindex="0">Forgotten username?</h2>
    <p class="description" id="dialogDesc" tabindex="0">
      You'll need your online banking username to access the eGIRO arrangement. Go to the
      <a href="https://www.hsbc.com.sg/contact/#call" class="link" target="_blank" rel="noopener noreferrer">HSBC
        website</a>
      to recover it. Once you have your username, come back here and continue.</p>
    <div class="button-box button-box-long-btn">
      <hr aria-hidden="true">
      <div btn class="exit-btn" (onClick)="closeModal('rbForgottenYourUsername')" [label]="'Close'" [className]="'red'">
      </div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="rbHardTokenForgetPin" *ngIf="openedModals?.rbHardTokenForgetPin"
  (closeLightboxEvent)="closeModal('rbHardTokenForgetPin')" [modalOutside]="true"
  [lastActivatedElement]="'rbwm-forget-password'" [maxWidth]="920" [identification]="'rbHardTokenForgetPin'"
  [xButton]="true">
  <div class="width-content">
    <div class="instructions-box">
      <h2 class="h2-modal" id="dialogTitle">Forgotten PIN</h2>
      <p class="description no-scroll top32" id="dialogDesc">
        If you enter an incorrect PIN, you will see 'FAIL 1' displayed on your Security Device. After 3 incorrect
        attempts you
        will see 'FAIL 3' and your Security Device will be locked.
        <br>
        <br>
        <br>
        If you lock your Security Device you will see a 7 digit code on the screen every time you turn it on. To reset
        your PIN,
        please
        <a href="https://www.hsbc.com.sg/contact/#call" class="link" target="_blank" rel="noopener noreferrer">contact
          us<i class="icon-new-window"></i></a> .
      </p>
      <div class="button-box">
        <hr aria-hidden="true">
        <div btn class="exit-btn" (onClick)="closeModal('rbHardTokenForgetPin')" [label]="'Close'" [className]="'red'">
        </div>
      </div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="rbSoftTokenForgetPin" *ngIf="openedModals?.rbSoftTokenForgetPin" [isWide]="true"
  (closeLightboxEvent)="closeModal('rbSoftTokenForgetPin')" [modalOutside]="true"
  [lastActivatedElement]="'rbwm-forget-password'" [maxWidth]="1024 " [identification]="'rbSoftTokenForgetPin'"
  [xButton]="true">
  <div class="width-content">
    <div class="instructions-box">
      <h2 class="h2-modal" id="dialogTitle">Forgotten your PIN?</h2>
      <hr aria-hidden="true">
      <div class="description longTextContent" id="dialogDesc" role="region" aria-label="Overview scroll window" tabindex="0">
        <div>
          <img src="./../../../../../assets/images/img_HSBC_Login_ForgotPIN_mAuth_1@2x.png">
          <p>
            From your HSBC Mobile Banking App launch screen, tap on the 'Forgotten your PIN'. If you normally log on
            with fingerprint or facial recognition, you'll need to cancel the prompt to see your launch screen.
          </p>
        </div>
        <div>
          <img src="./../../../../../assets/images/img_HSBC_Login_ForgotPIN_mAuth_2@2x.png">
          <p>
            Enter your password, select 'Continue' then request the SMS containing a one time security code.
          </p>
        </div>
        <div>
          <img src="./../../../../../assets/images/img_HSBC_Login_ForgotPIN_mAuth_3@2x.png">
          <p>
            You'll then be asked to enter your authentication code from the SMS.
          </p>
        </div>
        <div>
          <img src="./../../../../../assets/images/img_HSBC_Login_ForgotPIN_mAuth_4@2x.png">
          <p>
            Finally you'll need to create a new PIN and confirm it.
          </p>
        </div>
        <div>
          <img src="./../../../../../assets/images/img_HSBC_Login_ForgotPIN_mAuth_5@2x.png">
          <p>
            You'll receive confirmation that the PIN has been successfully updated. Please remember to use it to log on
            in future.
          </p>
        </div>
      </div>
    </div>

    <div class="button-box">
      <hr aria-hidden="true">
      <div btn class="exit-btn" (onClick)="closeModal('rbSoftTokenForgetPin')" [label]="'Close'" [className]="'red'"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="fdForgottenYourUsername" *ngIf="openedModals?.fdForgottenYourUsername"
  (closeLightboxEvent)="closeModal('fdForgottenYourUsername')" [modalOutside]="true"
  [focusElement]="'fd-forgot-username-focus-el'" [lastActivatedElement]="'fd-forget-password'"
  [identification]="'fdForgottenYourUsername'" [xButton]="true">
  <div class="instructions-box">
    <h2 class="fd-h2-modal" id="dialogTitle">Forgotten your username?</h2>
    <p class="description" id="dialogDesc">
      If you have forgotten your username call us on <span class="bold-text" *ngIf="!isDevice">03 456 100 100</span>
      <a *ngIf="isDevice" href="tel:03 456 100 100"><span class="bold-text">03 456 100 100</span></a>
    </p>
  </div>
  <hr aria-hidden="true">
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'fd-forgot-username-focus-el'" class="exit-btn" (onClick)="returnToThirdParty()"
      [label]="'Return to the billing organisation'" [className]="'fd-secondary'"></div>
    <div btn class="exit-btn" (onClick)="closeModal('fdForgottenYourUsername')" [label]="'Close'"
      [className]="'fd-primary'"></div>
  </div>
</app-lightbox>

<app-lightbox id="rbHardTokenStolenSecurityDevice" *ngIf="openedModals?.rbHardTokenStolenSecurityDevice"
  (closeLightboxEvent)="closeModal('rbHardTokenStolenSecurityDevice')" [modalOutside]="true"
  [identification]="'rbHardTokenStolenSecurityDevice'" [xButton]="true">
  <div class="instructions-box">
    <h2 class="h2-modal" id="dialogTitle">Lost, damaged or stolen Security Device</h2>
    <ul class="description longTextContent" id="dialogDesc">
      <span>To report a lost or stolen Security Device:</span>
      <li class="item">
        <i class="icon-contact-phone"></i>
        <span>For Personal Banking customers, please call our 24-hour Customer Service hotline on
          1800-HSBC NOW(4722 669) in Singapore or (65) 6-HSBC NOW (4722 669) from overseas.</span>
      </li>
      <li class="item">
        <i class="icon-contact-phone"></i>
        <span>For Premier customers, you can call us on 1800-227 8889 in Singapore or (65) 6216 9080 from
          overseas.</span>
      </li>
      <br>
      We encourage all customers to use the Digital Secure Key, which is available via the HSBC Singapore app. If you
      are unable to use the Digital Secure Key, you can request for a replacement Security Device by visiting a branch
      or
      <a href="https://www.hsbc.com.sg/" class="link" target="_blank" rel="noopener noreferrer">contacting us<i
          class="icon-new-window"></i></a>.
    </ul>
    <div class="button-box">
      <hr aria-hidden="true">
      <div btn class="exit-btn" (onClick)="closeModal('rbHardTokenStolenSecurityDevice')" [label]="'Close'"
        [className]="'red'"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="rbSoftTokenStolenSecurityDevice" *ngIf="openedModals?.rbSoftTokenStolenSecurityDevice"
  (closeLightboxEvent)="closeModal('rbSoftTokenStolenSecurityDevice')" [modalOutside]="true"
  [identification]="'rbSoftTokenStolenSecurityDevice'" [xButton]="true" [maxWidth]="880">
  <div class="width-content">
    <div class="instructions-box">
      <h2 class="h2-modal" id="dialogTitle">Lost, damaged or stolen Digital Secure Key</h2>
      <div class="description longTextContent" style="padding-top: 32px" id="dialogDesc">
        <span>You can remove your mobile device with Digital Secure Key through the HSBC Mobile Banking app or by
          calling us.</span>
        <div class="item">
          <h4 class="h4-modal">You can remove your mobile device with Digital Secure Key through the HSBC Singapore app.
          </h4>
          <div>
            <span>1. Log on to the app</span>
            <span>2. Tap on the 'Profile' from the bottom tab bar.</span>
            <span>3. Tap on the 'Security' from the list follow by 'Manage devices'</span>
            <span>4. Remove other device from the list</span>
          </div>
        </div>
        <div class="item">
          <h4 class="h4-modal">Call us for help</h4>
          <div>
            <span>If your mobile device with Digital Secure Key is lost, damaged or stolen we can deactivate your
              Digital Secure
              Key for you. Please <a href="https://www.hsbc.com.sg/contact/#call" class="link" target="_blank"
                rel="noopener noreferrer" aria-label="contact us(opens in a new window)">contact us</a><i class="icon-new-window"></i></span>
          </div>
        </div>

      </div>
      <div class="button-box">
        <hr aria-hidden="true">
        <div btn class="exit-btn" (onClick)="closeModal('rbSoftTokenStolenSecurityDevice')" [label]="'Close'"
          [className]="'red'"></div>
      </div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="mnsForgottenYourUsername" *ngIf="openedModals?.mnsForgottenYourUsername"
  (closeLightboxEvent)="closeModal('mnsForgottenYourUsername')" [modalOutside]="true"
  [focusElement]="'mns-forgot-username-focus-el'" [lastActivatedElement]="'mns-forget-password'"
  [identification]="'mnsForgottenYourUsername'" [xButton]="true">
  <div class="instructions-box">
    <h2 class="mns-h2-modal" id="dialogTitle">Forgotten your username?</h2>
    <p class="description" id="dialogDesc">You can get a reminder of your username by visiting the Internet Banking
      Sign-in page on the
      M&S Bank website</p>
  </div>
  <hr aria-hidden="true">
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'mns-forgot-username-focus-el'" class="exit-btn" (onClick)="returnToThirdParty()"
      [label]="'Return to the billing organisation'" [className]="'mns-white'"></div>
    <div btn class="exit-btn" (onClick)="closeModal('mnsForgottenYourUsername')" [label]="'Close'"
      [className]="'mns-primary'"></div>
  </div>
</app-lightbox>

<app-lightbox id="rbNotRegisteredForOpenBanking" *ngIf="openedModals?.rbNotRegisteredForOpenBanking"
  (closeLightboxEvent)="closeModal('rbNotRegisteredForOpenBanking')" [lastActivatedElement]="'rbwm-not-registered'"
  [identification]="'rbNotRegisteredForOpenBanking'" [modalOutside]="true" [focusElement]="'rb-not-registered-focus-el'"
  [xButton]="true">
  <div class="instructions-box">
    <h2 class="h2-modal" id="dialogTitle">Not registered for Online Banking?</h2>
    <p class="description" id="dialogDesc">You'll need your online banking username to access the eGIRO arrangement. Go
      to the
      <a href="https://www.hsbc.com.sg/" class="link" target="_blank" rel="noopener noreferrer">HSBC website</a> to
      recover it. Once you have your username, come back here and continue.
    </p>
    <div class="button-box">
      <hr aria-hidden="true">
      <div btn class="exit-btn" (onClick)="closeModal('rbNotRegisteredForOpenBanking')" [label]="'Close'"
        [className]="'red'"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="fdNotRegisteredForOpenBanking" *ngIf="openedModals?.fdNotRegisteredForOpenBanking"
  (closeLightboxEvent)="closeModal('fdNotRegisteredForOpenBanking')" [lastActivatedElement]="'fd-not-registered'"
  [identification]="'fdNotRegisteredForOpenBanking'" [modalOutside]="true" [focusElement]="'fd-not-registered-focus-el'"
  [xButton]="true">
  <div class="instructions-box">
    <h2 class="fd-h2-modal" id="dialogTitle">Not registered for Online Banking?</h2>
    <p class="description" id="dialogDesc">You need to register for Online Banking to use Open Banking. To register,
      go to
      firstdirect.com.</p>
  </div>
  <hr aria-hidden="true">
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'fd-not-registered-focus-el'" class="exit-btn" (onClick)="returnToThirdParty()"
      [label]="'Return to the billing organisation'" [className]="'fd-secondary'"></div>
    <div btn class="exit-btn" (onClick)="closeModal('fdNotRegisteredForOpenBanking')" [label]="'Close'"
      [className]="'fd-primary'"></div>
  </div>
</app-lightbox>
<app-lightbox id="mnsNotRegisteredForOpenBanking" *ngIf="openedModals?.mnsNotRegisteredForOpenBanking"
  (closeLightboxEvent)="closeModal('mnsNotRegisteredForOpenBanking')" [lastActivatedElement]="'mns-not-registered'"
  [identification]="'mnsNotRegisteredForOpenBanking'" [modalOutside]="true"
  [focusElement]="'mns-not-registered-focus-el'" [xButton]="true">
  <div class="instructions-box">
    <h2 class="mns-h2-modal" id="dialogTitle">Not registered for Internet Banking?</h2>
    <p class="description" id="dialogDesc">You need to register for Internet Banking to use Open Banking. To register,
      go to the M&S Bank website.</p>
  </div>
  <hr aria-hidden="true">
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'mns-not-registered-focus-el'" class="exit-btn" (onClick)="returnToThirdParty()"
      [label]="'Return to the billing organisation'" [className]="'mns-white'"></div>
    <div btn class="exit-btn" (onClick)="closeModal('mnsNotRegisteredForOpenBanking')" [label]="'Close'"
      [className]="'mns-primary'"></div>
  </div>
</app-lightbox>

<app-lightbox id="rbLostSecureKey" *ngIf="openedModals?.rbLostSecureKey" [identification]="'rbLostSecureKey'"
  (closeLightboxEvent)="closeModal('rbLostSecureKey')" [lastActivatedElement]="'rbwm-lost-pass'" [modalOutside]="true"
  [focusElement]="'rb-contact-support-focus-el'" [xButton]="true">
  <div>
    <h2 class="h2-modal" id="dialogTitle">Lost, damaged or stolen Secure&nbsp;Key</h2>
    <p class="description" id="dialogDesc">To report a lost or stolen Security Device:</p>
    <p class="description"><i class="icon icon-phone" aria-hidden="true"></i>For Personal Banking customers, please call
      our
      24-hour Customer Service hotline on 1800-HSBC NOW (4722 669) in Singapore
      or (65) 6-HSBC NOW (4722 669) from overseas.</p>
    <p class="description"><i class="icon icon-phone" aria-hidden="true"></i>For Premier customers, you can call us on
      1800-227
      8889 in Singapore or (65) 6216 9080 from overseas.</p>
    <p class="description">To request a form for a replacement Security Device, please call us on 1800-HSBC NOW (4722
      669) in
      Singapore
      or (65) 6-HSBC NOW (4722 669) from overseas or go to <a href="https:www.hsbc.com.sg/" class="link" target="_blank"
        rel="noopener noreferrer">www
        .hsbc.com.sg</a>
    </p>
  </div>
  <hr aria-hidden="true">
  <div class="button-box">
    <div btn class="exit-btn" (onClick)="closeModal('rbLostSecureKey')" [label]="'Close'" [className]="'red'"></div>
  </div>
</app-lightbox>
<app-lightbox id="fdLostSecureKey" *ngIf="openedModals?.fdLostSecureKey" [identification]="'fdLostSecureKey'"
  (closeLightboxEvent)="closeModal('fdLostSecureKey')" [lastActivatedElement]="'fd-lost-pass'" [modalOutside]="true"
  [focusElement]="'fd-contact-support-focus-el'" [xButton]="true">
  <div>
    <h2 class="fd-h2-modal modal-title" id="dialogTitle">Lost, damaged or stolen Secure&nbsp;Key</h2>
    <p class="description" id="dialogDesc">Please call us at any time on
      <span *ngIf="!isDevice">03 456 100 100</span>
      <a *ngIf="isDevice" href="tel:03 456 100 100">03 456 100 100</a>
      to report a lost, damaged or stolen device</p>
  </div>
  <hr aria-hidden="true">
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'fd-contact-support-focus-el'" class="exit-btn" (onClick)="returnToThirdParty()"
      [label]="'Return to the billing organisation'" [className]="'fd-secondary'"></div>
    <div btn class="exit-btn" (onClick)="closeModal('fdLostSecureKey')" [label]="'Close'" [className]="'fd-primary'"></div>
  </div>
</app-lightbox>
<app-lightbox id="mnsLostSecureKey" *ngIf="openedModals?.mnsLostSecureKey" [identification]="'mnsLostSecureKey'"
  (closeLightboxEvent)="closeModal('mnsLostSecureKey')" [lastActivatedElement]="'mns-lost-pass'" [modalOutside]="true"
  [focusElement]="'mns-contact-support-focus-el'" [xButton]="true">
  <div>
    <h2 class="mns-h2-modal" id="dialogTitle">Lost, damaged or stolen M&S&nbsp;PASS</h2>
    <p class="description" id="dialogDesc">Please call us at any time on <span *ngIf="!isDevice">0345 900 0900</span> <a
        *ngIf="isDevice" href="tel:0345 900 0900">0345 900 0900</a> to
      report a lost, damaged or stolen M&S PASS</p>
  </div>
  <hr aria-hidden="true">
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'mns-contact-support-focus-el'" class="exit-btn" (onClick)="returnToThirdParty()"
      [label]="'Return to the billing organisation'" [className]="'mns-white'"></div>
    <div btn class="exit-btn" (onClick)="closeModal('mnsLostSecureKey')" [label]="'Close'" [className]="'mns-primary'">
    </div>
  </div>
</app-lightbox>

<app-lightbox id="aispWhyCantISeeAllMyAccounts" *ngIf="openedModals?.aispWhyCantISeeAllMyAccounts"
  (closeLightboxEvent)="closeModal('aispWhyCantISeeAllMyAccounts')"
  [lastActivatedElement]="'shared-' + (brandId === 'rb' ? 'rbwm' : brandId) + '-account-hint'" [modalOutside]="true"
  [identification]="'aispWhyCantISeeAllMyAccounts'" [focusElement]="brandId + '-cant-see-acct-focus-el'"
  [xButton]="true">
  <div>
    <h2 id="dialogTitle" class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal">Why can't I see all my
      accounts?</h2>
    <p id="dialogDesc" class="text">We only show accounts you can share the requested information with using Open
      Banking</p>
  </div>
  <div class="button-box">
    <div btn [idName]="brandId + '-cant-see-acct-focus-el'" [label]="'Close'" [className]="getPrimaryButtonClassName()"
      (onClick)="closeModal('aispWhyCantISeeAllMyAccounts')"></div>
  </div>
</app-lightbox>

<app-lightbox id="pispWhyCantISeeAllMyAccounts" *ngIf="openedModals?.pispWhyCantISeeAllMyAccounts"
  (closeLightboxEvent)="closeModal( 'pispWhyCantISeeAllMyAccounts')"
  [lastActivatedElement]="(brandId === 'rb' ? 'rbwm' : brandId) + '-account-hint'" [modalOutside]="true"
  [identification]="'pispWhyCantISeeAllMyAccounts'" [focusElement]="brandId + '-cant-see-acct-pisp-focus-el'"
  [xButton]="true">
  <div>
    <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
      Why can't I see all my accounts?
    </h2>
    <p id="dialogDesc" class="text">We only show eligible accounts with sufficient funds that you can use Open Banking
      to make payments from</p>
  </div>
  <div class="button-box">
    <div btn [label]="'Close'" [id]="brandId + '-cant-see-acct-pisp-focus-el'" [className]="getPrimaryButtonClassName()"
      (onClick)="closeModal('pispWhyCantISeeAllMyAccounts')"></div>
  </div>
</app-lightbox>

<app-lightbox *ngIf="openedModals?.informationSharingIncomplete"
  [lastActivatedElement]="brandId === 'rb' ? 'rbwm' : brandId + '-cancel-button'" id="informationSharingIncomplete"
  [identification]="'informationSharingIncomplete'" (closeLightboxEvent)="closeModal('informationSharingIncomplete')"
  [modalOutside]="true" [focusElement]="brandId + '-info-incomplete-focus-el'" [xButton]="true">
  <div class="instructions-box">
    <h2 class="mns-h2-modal" id="dialogTitle">Are you sure you want to leave this page?</h2>
    <p class="description" id="dialogDesc">All the changes you've made will be lost.</p>
    <div class="button-box button-box-long-btn">
      <hr aria-hidden="true">
      <div btn [label]="'Yes, return to the Billing Organisation'" [className]="getSecondaryButtonClassName()"
        (onClick)="returnToThirdParty()"></div>
      <div btn [idName]="brandId + '-info-incomplete-focus-el'" [label]="'No, stay on this page'"
        [className]="getPrimaryButtonClassName()" (onClick)="closeModal('informationSharingIncomplete')"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="fundsAvailabilityCheckIncomplete" *ngIf="openedModals?.fundsAvailabilityCheckIncomplete"
  (closeLightboxEvent)="closeModal('fundsAvailabilityCheckIncomplete')"
  [identification]="'fundsAvailabilityCheckIncomplete'" [modalOutside]="true"
  [focusElement]="brandId + '-fund-check-focus-el'" [xButton]="true">
  <h2 id="dialogTitle" class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal">
    Funds availability check incomplete
  </h2>

  <p id="dialogDesc" class="text">
    Select "Return to the billing organisation " to return to {{tppName ? tppName : 'the billing organisation'}}
  </p>

  <div class="button-box button-box-long-btn">
    <div btn [idName]="brandId + '-fund-check-focus-el'" [label]="'Cancel'" [className]="getSecondaryButtonClassName()"
      (onClick)="closeModal('fundsAvailabilityCheckIncomplete')"></div>
    <div btn [idName]="brandId + '-return-to-third-party-bt'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()"
      (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="paymentIncomplete" *ngIf="openedModals?.paymentIncomplete"
  (closeLightboxEvent)="closeModal('paymentIncomplete')" [identification]="'paymentIncomplete'" [modalOutside]="true"
  [focusElement]="brandId + '-payment-incomplete-focus-el'" [lastActivatedElement]="brandId + '-cancel-popup'"
  [xButton]="true">
  <h2 id="dialogTitle" class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal">
    Payment incomplete
  </h2>
  <div id="dialogDesc">
    <p class="text" *ngIf="isStandingOrder">
      You haven't set up this {{isPaymentInternational ? 'recurring payment' : 'standing order'}}.
    </p>
    <p class="text">
      Select "Return to the billing organisation " to return to {{tppName ? tppName : 'the billing organisation'}}
    </p>
  </div>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="brandId + '-payment-incomplete-focus-el'" [label]="'Cancel'" [className]="getSecondaryButtonClassName()"
      (onClick)="closeModal('paymentIncomplete')"></div>
    <div btn [label]="'Return to the billing organisation'" [className]="getPrimaryButtonClassName()"
      (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="permanentLockout" *ngIf="openedModals?.permanentLockout" [modalOutside]="true"
  [focusElement]="brandId + '-perm-lock-focus-el'" (closeLightboxEvent)="closeModal('permanentLockout')"
  [identification]="'permanentLockout'">

  <div *ngIf="brandId === 'rb'">
    <h2 class="h2-modal h2-modal--spacing" id="dialogTitle">
      <span class="out-of-sight">Error message </span>
      <span>For your security you have been locked out of all digital banking services due to too
        many failed log on attempts and will be returned to the third party</span>
    </h2>
    <div class="permanent-lockout-instructions" id="dialogDesc">
      <div>
        <img class="permanent-lockout-instructions__img" src="../../../../../../assets/images/last.png" alt="">
        <div class="permanent-lockout-instructions__description">
          <p class="permanent-lockout-instructions__text">Using your HSBC Secure Key? Please call us on <span
              *ngIf="!isDevice">0345 600 2290</span>
            <a *ngIf="isDevice" href="tel:0345 600 2290">0345 600 2290</a> to restore your access.
          </p>
        </div>
      </div>
      <div>
        <img class="permanent-lockout-instructions__img" src="../../../../../../assets/images/one-m.png" alt="">
        <div class="permanent-lockout-instructions__description permanent-lockout-instructions__description--position">
          <p class="permanent-lockout-instructions__text">Using your HSBC Digital Secure Key? You can regain access
            instantly by resetting your Digital Secure Key password within HSBC Mobile Banking App.</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="brandId === 'fd'">
    <h2 class="fd-h2-modal" id="dialogTitle">Security reset</h2>
    <div id="dialogDesc">
      <p class="text">Unfortunately, we can't proceed with your request and you will now be returned to the third
        party.
        Please call us on <strong *ngIf="!isDevice">03 456 100 100</strong>
        <a *ngIf="isDevice" href="tel:03 456 100 100"><strong>03 456 100 100</strong></a> and we will be happy to help
        you.
        Please quote reference
        <strong>TSN_0002</strong></p>
    </div>
  </div>

  <div *ngIf="brandId === 'mns'">
    <h2 class="mns-h2-modal" id="dialogTitle">You are locked out of internet banking</h2>
    <div id="dialogDesc">
      <p class="text">There have been too many failed sign in attempts. To keep your account safe we've locked your
        security code and you will now be returned to the billing organisation .</p>
      <p class="text">You can call <span *ngIf="!isDevice">0345 900 0900</span><a *ngIf="isDevice"
          href="tel:0345 900 0900">0345 900 0900</a> to
        reset your M&S PASS</p>
    </div>
  </div>

  <div class="button-box">
    <div btn [idName]="brandId + '-perm-lock-focus-el'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="invalidUsernameLastAttempt" *ngIf="openedModals?.invalidUsernameLastAttempt"
  (closeLightboxEvent)="closeModal('invalidUsernameLastAttempt')" [modalOutside]="true"
  [identification]="'invalidUsernameLastAttempt'" [focusElement]="brandId + '-invalid-username-last-focus-el'">
  <div class="instructions-box" *ngIf="brandId === 'rb'">
    <h2 class="mns-h2-modal" id="dialogTitle">Forgotten your username?</h2>
    <p class="description" id="dialogDesc">You've reached the maximum logon attempts. To recover your username, please
      <a href="https://www.hsbc.com.sg/contact/#call" class="link" target="_blank" rel="noopener noreferrer">contact
        us</a>
      <i class="icon-new-window"></i>.</p>
    <div class="button-box button-box-long-btn">
      <hr aria-hidden="true">

      <div btn [label]="'Return to the billing organisation'" [className]="getPrimaryButtonClassName()"
        (onClick)="returnToThirdParty()"></div>
    </div>
  </div>
  <div *ngIf="brandId === 'fd'">
    <h2 class="fd-h2-modal" id="dialogTitle">
      We don't recognise the username you've entered.
    </h2>
    <p class="text">If you have forgotten your username call us on <span *ngIf="!isDevice">03 456 100 100</span>
      <a *ngIf="isDevice" href="tel:03 456 100 100">03 456 100 100</a>.</p>
  </div>
  <div *ngIf="brandId === 'mns'">
    <h2 class="mns-h2-modal" id="dialogTitle">
      Your username was not recognised.
    </h2>
    <p class="text">You can get a reminder of your username by visiting the Internet Banking Sign-in
      page on the M&S Bank website.</p>
  </div>

  <div class="button-box button-box-long-btn" *ngIf="brandId !== 'rb'">
    <div btn [label]="'Return to the billing organisation'" [id]="brandId + '-invalid-username-last-focus-el'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="redirectionToTpp" *ngIf="openedModals?.redirectionToTpp"
  (closeLightboxEvent)="closeModal('redirectionToTpp')" [modalOutside]="true" [identification]="'redirectionToTpp'">
  <h2 id="dialogTitle" class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal">Redirect to Tpp</h2>
  <p id="dialogDesc" class="text">
    You will be automatically returned to {{tppName}}.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [label]="'Return to the billing organisation'" [className]="getPrimaryButtonClassName()"
      (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="lastTimeoutWarning" *ngIf="openedModals?.lastTimeoutWarning" [identification]="'lastTimeoutWarning'"
  (closeLightboxEvent)="closeModal('lastTimeoutWarning')" [modalOutside]="true">
  <h2 id="dialogTitle" class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal">Session will timeout in 5 minutes</h2>
  <p id="dialogDesc" class="text">
    This session will time out in 5 minutes and you’ll be redirected to the billing organisation’s website.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [label]="'Return to the billing organisation'" [className]="getSecondaryButtonClassName()"
      (onClick)="returnToThirdParty()"></div>
    <div btn [label]="'Stay'" [className]="getPrimaryButtonClassName()" (onClick)="stayLonger('lastTimeoutWarning')"></div>
  </div>
</app-lightbox>

<app-lightbox id="timeoutWarning" *ngIf="openedModals?.timeoutWarning"
  (closeLightboxEvent)="closeModal('timeoutWarning')" [modalOutside]="true" [identification]="'timeoutWarning'"
  [xButton]="true">
  <div class="instructions-box">
    <h2 id="dialogTitle" class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal">Session will timeout in {{sec}}
      seconds</h2>
    <p id="dialogDesc" class="description">
      This session will time out in 1 minute and you’ll be redirected to the billing organisation’s website.
    </p>
    <div class="button-box button-box-long-btn">
      <hr aria-hidden="true">
      <div btn *ngIf="canStayLonger" [label]="'Close'" [className]="getPrimaryButtonClassName()"
        (onClick)="stayLonger('timeoutWarning')"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="informationDoesNotMatchRecords" *ngIf="openedModals?.informationDoesNotMatchRecords"
  (closeLightboxEvent)="closeModal('informationDoesNotMatchRecords')" [modalOutside]="true"
  [identification]="'informationDoesNotMatchRecords'"
  [focusElement]="brandId + '-information-does-not-match-records-focus-el'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">The information you entered does not
    match our records</h2>
  <div class="button-box button-box-long-btn">
    <div btn [label]="'Return to the billing organisation'" [id]="brandId + '-information-does-not-match-records-focus-el'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="payeeDetailsNotValid" *ngIf="openedModals?.payeeDetailsNotValid"
  (closeLightboxEvent)="closeModal('payeeDetailsNotValid')" [modalOutside]="true"
  [identification]="'payeeDetailsNotValid'" [focusElement]="brandId + '-payee-details-not-valid-focus-el'"
  [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">Payee details not valid</h2>
  <div id="dialogDesc">
    <p class="text">
      We can't process this payment,
    </p>
    <p class="text">
      You'll now be returned to {{tppName}}.
    </p>
  </div>
  <div class="button-box button-box-long-btn">
    <div btn [label]="'Return to the billing organisation'" [id]="brandId + '-payee-details-not-valid-focus-el'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="serviceUnavailable" *ngIf="openedModals?.serviceUnavailable"
  (closeLightboxEvent)="closeModal('serviceUnavailable')" [modalOutside]="true" [focusElement]="'serviceUnavailableEl'"
  [identification]="'serviceUnavailable'">
  <div class="instructions-box">
    <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">Service unavailable</h2>
    <div id="dialogDesc" class="description">
      <p class="text">We're sorry, this service is temporarily unavailable. Please try again later.</p>
    </div>
    <div class="button-box button-box-long-btn">
      <hr aria-hidden="true">
      <div btn [idName]="'serviceUnavailableEl'" [label]="'Return to the billing organisation'"
        [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="insufficientReference" *ngIf="openedModals?.insufficientReference"
  (closeLightboxEvent)="closeModal('insufficientReference')" [modalOutside]="true"
  [focusElement]="'insufficientReferenceEl'" [identification]="'insufficientReference'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    To make a payment online, your reference must contain at least 4 digits.
  </h2>
  <p id="dialogDesc" class="text">
    Select "Return to the billing organisation" to return to {{tppName}}.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'insufficientReferenceEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="amountLessThanFees" *ngIf="openedModals?.amountLessThanFees"
  (closeLightboxEvent)="closeModal('amountLessThanFees')" [modalOutside]="true" [focusElement]="'amountLessThanFeesEl'"
  [identification]="'amountLessThanFees'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    Payment amount less than charges
  </h2>
  <p id="dialogDesc" class="text">
    The payment amount indicated is less than the charges to be taken. You’ll now be returned to the billing
    organisation.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'amountLessThanFeesEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="amountLessThanGBP" *ngIf="openedModals?.amountLessThanGBP"
  (closeLightboxEvent)="closeModal('amountLessThanGBP')" [modalOutside]="true" [focusElement]="'amountLessThanGBPEl'"
  [identification]="'amountLessThanGBP'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    Payment under minimum amount
  </h2>
  <p id="dialogDesc" class="text">
    The minimum payment amount is equivalent to 1 GBP. You’ll now be returned to the billing organisation.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'amountLessThanGBPEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="insufficientFunds" *ngIf="openedModals?.insufficientFunds"
  (closeLightboxEvent)="closeModal('insufficientFunds')" [modalOutside]="true" [focusElement]="'insufficientFundsEl'"
  [identification]="'insufficientFunds'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    Insufficient funds
  </h2>
  <p id="dialogDesc" class="text">
    You don’t have enough money in your account to send this payment. You'll now be returned to the billing
    organisation.</p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'insufficientFundsEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="insufficientFundsOnThisAccount" *ngIf="openedModals?.insufficientFundsOnThisAccount"
  (closeLightboxEvent)="closeModal('insufficientFundsOnThisAccount')" [modalOutside]="true"
  [focusElement]="'insufficientFundsOnThisAccountContinueEl'" [identification]="'insufficientFundsOnThisAccount'"
  [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    Insufficient funds
  </h2>
  <p class="text" id="dialogDesc">
    You don’t have enough money in your account to send this payment. Select 'Select another account' to proceed with
    the
    payment using a different account or 'Return to {{tppName}}' to cancel or amend the payment.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'insufficientFundsOnThisAccountRedirectEl'" [label]="'Return to the billing organisation'"
      [className]="getSecondaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
    <div btn [idName]="'insufficientFundsOnThisAccountContinueEl'" [label]="'Select another account'"
      [className]="getPrimaryButtonClassName()" (onClick)="closeModal('insufficientFundsOnThisAccount')"></div>
  </div>
</app-lightbox>

<app-lightbox id="unablePaymentOnSelectedDate" *ngIf="openedModals?.unablePaymentOnSelectedDate"
  (closeLightboxEvent)="closeModal('unablePaymentOnSelectedDate')" [modalOutside]="true"
  [focusElement]="'unablePaymentOnSelectedDateEl'" [identification]="'unablePaymentOnSelectedDate'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    We're unable to send a payment on the selected date.
  </h2>
  <p id="dialogDesc" class="text">
    We can only send payments on a working day. You’ll now be returned to the billing organisation.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'unablePaymentOnSelectedDateEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="duplicatePayment" *ngIf="openedModals?.duplicatePayment"
  (closeLightboxEvent)="closeModal('duplicatePayment')" [modalOutside]="true"
  [focusElement]="'duplicatePaymentContinueEl'" [identification]="'duplicatePayment'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    Duplicate payment
  </h2>
  <p id="dialogDesc" class="text">
    These details match another payment already set up on your account. Select 'Continue' if you wish to proceed or
    'Return to
    the billing organisation' to return to {{tppName}} to cancel or amend the payment.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'duplicatePaymentRedirectEl'" [label]="'Return to the billing organisation'"
      [className]="getSecondaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
    <div btn [idName]="'duplicatePaymentContinueEl'" [label]="'Continue'" [className]="getPrimaryButtonClassName()"
      (onClick)="closeModal('duplicatePayment')"></div>
  </div>
</app-lightbox>

<app-lightbox id="noEligibleAccountsAisp" *ngIf="openedModals?.noEligibleAccountsAisp" [modalOutside]="true"
  (closeLightboxEvent)="closeModal('noEligibleAccountsAisp')" [focusElement]="'noEligibleAccountsAispEl'"
  [identification]="'noEligibleAccountsAisp'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    No eligible accounts
  </h2>
  <p class="text" id="dialogDesc">
    We can't share the requested information from any of your accounts. You'll now be returned to the billing
    organisation.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'noEligibleAccountsAispEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="noEligibleAccountsCbpii" *ngIf="openedModals?.noEligibleAccountsCbpii"
  (closeLightboxEvent)="closeModal('noEligibleAccountsCbpii')" [modalOutside]="true"
  [focusElement]="'noEligibleAccountsCbpiiEl'" [identification]="'noEligibleAccountsCbpii'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    Account not eligible
  </h2>
  <p id="dialogDesc" class="text">
    The requested account is not eligible to be used for a funds availability check. <br>
    You'll now be returned to the third
    party. </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'noEligibleAccountsCbpiiEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="transactionCountLimit" *ngIf="openedModals?.transactionCountLimit"
  [identification]="'transactionCountLimit'" (closeLightboxEvent)="closeModal('transactionCountLimit')"
  [modalOutside]="true" [focusElement]="'transactionCountLimitEl'" [xButton]="true">
  <p class="text" id="dialogTitle">
    You have exceeded the daily transaction limit and will be unable to send this money. Select ‘Return to
    the billing organisation’
    to return to {{tppName}}.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'transactionCountLimitEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="transactionAmountLimit" *ngIf="openedModals?.transactionAmountLimit"
  (closeLightboxEvent)="closeModal('transactionAmountLimit')" [modalOutside]="true"
  [focusElement]="'transactionAmountLimitEl'" [identification]="'transactionAmountLimit'" [xButton]="true">
  <p class="text" id="dialogDesc">
    The transaction amount is greater than the daily transaction limit so you will be unable to send this money. Select
    ‘Return to the billing organisation’ to return to {{tppName}}.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'transactionAmountLimitEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="exceedsLimitForCurrency" *ngIf="openedModals?.exceedsLimitForCurrency"
  (closeLightboxEvent)="closeModal('exceedsLimitForCurrency')" [modalOutside]="true"
  [focusElement]="'exceedsLimitForCurrencyEl'" [identification]="'exceedsLimitForCurrency'">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    The transaction exceeds the limit for this currency. You’ll now be returned to the billing organisation.
  </h2>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'exceedsLimitForCurrencyEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="noEligibleAccountsPisp" *ngIf="openedModals?.noEligibleAccountsPisp" [modalOutside]="true"
  (closeLightboxEvent)="closeModal('noEligibleAccountsPisp')" [focusElement]="'noEligibleAccountsPispEl'"
  [identification]="'noEligibleAccountsPisp'" [xButton]="true">
  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    No eligible accounts
  </h2>
  <div id="dialogDesc">
    <p class="text">
      {{additionalWording}}
    </p>
    <p class="text">
      You'll now be returned to the billing organisation.
    </p>
  </div>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'noEligibleAccountsPispEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="inEligiblePaymentAccount" *ngIf="openedModals?.inEligiblePaymentAccount"
  [identification]="'inEligiblePaymentAccount'" (closeLightboxEvent)="closeModal('inEligiblePaymentAccount')"
  [modalOutside]="true" [focusElement]="'ineligiblePaymentAccountEl'" [xButton]="true">

  <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle">
    Ineligible payment account
  </h2>
  <p class="text" id="dialogTitle">
    The requested account is not eligible to be used for sending money.
  </p>
  <div class="button-box button-box-long-btn">
    <div btn [idName]="'inEligiblePaymentAccountEl'" [label]="'Return to the billing organisation'"
      [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
  </div>
</app-lightbox>

<app-lightbox id="sanctionCountryError" *ngIf="openedModals?.sanctionCountryError"
  [identification]="'sanctionCountryError'" [modalOutside]="true" [focusElement]="'sanctionCountryErrorEl'"
  (closeLightboxEvent)="closeModal('sanctionCountryError')">
  <div class="instructions-box">
    <h2 class="{{brandId === 'rb' ? '' : brandId + '-'}}h2-modal" id="dialogTitle"> HSBC does not provide this service
      in your location.</h2>
    <p class="text description" id="dialogTitle"></p>
    <div class="button-box button-box-long-btn">
      <hr aria-hidden="true">
      <div btn [idName]="'sanctionCountryErrorEl'" [label]="'Return to the billing organisation'"
        [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="rbHelpWithSecurityDevice" *ngIf="openedModals?.rbHelpWithSecurityDevice"
  [identification]="'rbHelpWithSecurityDevice'" (closeLightboxEvent)="closeModal('rbHelpWithSecurityDevice')"
  [maxWidth]="1024" [modalOutside]="true" [xButton]="true">
  <div class="instructions-box help-device-wrapper">
    <h2 class="h2-modal" id="dialogTitle">Help with your Security Device</h2>
    <app-help-security-device></app-help-security-device>
    <div class="button-box">
      <hr aria-hidden="true">
      <div btn class="exit-btn" (onClick)="closeModal('rbHelpWithSecurityDevice')" [label]="'Close'" [className]="'red'">
      </div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="sessionDeny" *ngIf="openedModals?.sessionDeny" (closeLightboxEvent)="returnToThirdParty()"
  [modalOutside]="true" [identification]="'timeoutWarning'">
  <div class="instructions-box">
    <h2 class="h2-modal" id="dialogTitle">Service unavailable</h2>
    <div id="dialogDesc" class="description">
      <p class="text">We're sorry, this service is temporarily unavailable. Please try again later.</p>
    </div>
    <div class="button-box button-box-long-btn">
      <hr aria-hidden="true">
      <div btn [idName]="'serviceUnavailableEl'" [label]="'Return to the billing organisation'"
        [className]="getPrimaryButtonClassName()" (onClick)="returnToThirdParty()"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="userSuspended" *ngIf="openedModals?.userSuspended" (closeLightboxEvent)="returnToThirdParty()"
  [modalOutside]="true" [identification]="'userSuspended'">
  <div class="instructions-box">
    <h2 class="h2-modal" id="dialogTitle">User suspended</h2>
    <div class="description" id="dialogDesc">
      <p>Your profile has been suspended. Please <a href="https://www.hsbc.com.sg/contact/#call" class="link"
          target="_blank" rel="noopener noreferrer" aria-label="contact us(opens in a new window)">contact us</a>
        <i class="icon-new-window" style="margin-left: 2px;margin-right: 2px"></i> for assistance.</p>
    </div>
    <div class="button-box">
      <hr aria-hidden="true">
      <div btn [label]="'Return to the billing organisation'" [className]="'red'" (onClick)="returnToThirdParty()"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="userAbnormalStatus" *ngIf="openedModals?.userAbnormalStatus"
  (closeLightboxEvent)="returnToThirdParty()" [modalOutside]="true" [identification]="'userAbnormalStatus'">
  <div class="instructions-box">
    <h2 class="h2-modal" id="dialogTitle">Unable to proceed</h2>
    <div class="description" id="dialogDesc">
      Your user profile is inactive. Please <a href="https://www.hsbc.com.sg/contact/#call" class="link" target="_blank"
        rel="noopener noreferrer" aria-label="contact us(opens in a new window)">contact us</a>
      <i class="icon-new-window" style="margin-left: 2px;margin-right: 2px"></i>.
    </div>
    <div class="button-box">
      <hr aria-hidden="true">
      <div btn [label]="'Return to the billing organisation'" [className]="'red'" (onClick)="returnToThirdParty()"></div>
    </div>
  </div>
</app-lightbox>


<app-lightbox id="logonCrossIdError" *ngIf="openedModals?.logonCrossIdError" (closeLightboxEvent)="returnToThirdParty()"
  [modalOutside]="true" [identification]="'logonCrossIdError'">
  <div class="instructions-box">
    <h2 class="h2-modal" id="dialogTitle">Confirm your identity</h2>
    <div class="description" id="dialogDesc">
      <div>
        We don't have your updated ID in our records. Please visit the nearest branch and carry your original NRIC
        card/EP and passport to have your records updated.
      </div>
    </div>
    <div class="button-box">
      <hr aria-hidden="true">
      <div btn [label]="'Return to the billing organisation'" [className]="'red'" (onClick)="returnToThirdParty()"></div>
    </div>
  </div>
</app-lightbox>

<app-lightbox id="submitEddaSuccess" *ngIf="openedModals?.submitEddaSuccess" (closeLightboxEvent)="returnToThirdParty()"
  [modalOutside]="true" [identification]="'logonCrossIdError'">
  <div class="instructions-box">
    <h2 class="h2-modal" id="dialogTitle">Confirm your identity</h2>
    <div class="description" id="dialogDesc">
      <div>
        We don't have your updated ID in our records. Please visit the nearest branch and carry your original NRIC
        card/EP and passport to have your records updated.
      </div>
    </div>
    <div class="button-box">
      <hr aria-hidden="true">
      <div btn [label]="'Return to the billing organisation'" [className]="'red'" (onClick)="returnToThirdParty()"></div>
    </div>
  </div>
</app-lightbox>