import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErrorItem } from '../error-item';

@Component({
    selector: 'app-input-search',
    templateUrl: './input-search.component.html',
    styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {


    @Output() chooseErrorItemEvent: EventEmitter<ErrorItem> = new EventEmitter();
    @Input() ErrorArrays: Array<ErrorItem>;
    value = null;

    chooseError = 'option00';
    isShowTips = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    clearValue() {
        this.value = '';
        this.isShowTips = false;
    }

    choiceErrorItem(item) {
        this.chooseError = item;
        this.chooseErrorItemEvent.emit(item);
    }
}
