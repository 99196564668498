import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ServiceUnavailableComponent} from "./service-unavailable.component";
import {LightboxModule} from "../lightbox/lightbox.module";
import {ButtonModule} from "../button/button.module";

@NgModule({
  declarations: [ServiceUnavailableComponent],
  imports: [
    CommonModule,
    LightboxModule,
    ButtonModule
  ],
  exports: [ServiceUnavailableComponent]
})
export class ServiceUnavailableModule {
}
