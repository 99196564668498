import {Pipe, PipeTransform} from '@angular/core';
import {ErrorItem} from '../error-item';

@Pipe({name: 'appInputFilter'})
export class InputSearchPipe implements PipeTransform {

    transform(items: ErrorItem[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }

        return items.filter(it => {
            return it.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
        });
    }
}
