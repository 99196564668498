import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavTabComponent} from './nav-tab.component';

@NgModule({
    declarations: [NavTabComponent],
    imports: [
        CommonModule
    ],
    exports: [
        NavTabComponent
    ]
})
export class NavTabModule {
}
