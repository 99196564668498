import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TextContentService {

  changeLanguageEvent: Subject<null> = new Subject();
  config = {
    cookieExpirationTime: 365
  };
  textContents = {};
  textContent = {};
  chosenLanguage: string;

  constructor(private http: HttpClient) {
  }

  getTextContent() {
    this.chosenLanguage = this.getLanguageFromCookie() ? this.getLanguageFromCookie() : 'en';
    return new Promise((resolve, reject) => {
      this.http.get(`../assets/i18n/${this.chosenLanguage}.json`).subscribe(
        textContent => {
          this.textContents[this.chosenLanguage] = textContent;
          this.textContent = textContent;
          resolve(true);
        },
        error => resolve(error));
    })
  }

  changeLanguage(languageId: string) {
    if (this.textContents[languageId]) {
      this.saveLanguageToCookie(languageId);
      this.textContent = this.textContents[languageId];
      this.changeLanguageEvent.next();
    } else {
      this.http.get(`../assets/i18n/${languageId}.json`).subscribe(
        textContent => {
          this.saveLanguageToCookie(languageId);
          this.chosenLanguage = languageId;
          this.textContents[languageId] = textContent;
          this.textContent = textContent;
          this.changeLanguageEvent.next();
        });
    }
  }

  getLanguageFromCookie() {
    const regex = /HSBC-AUTH-PAGE-LANGUAGE='.+'/;
    if (!document.cookie.match(regex)) return '';
    const languageFromCookie = document.cookie.match(regex)[0].split('\'')[1];
    return languageFromCookie;
  }

  saveLanguageToCookie(languageId) {
    const d = new Date();
    d.setTime(d.getTime() + (this.config.cookieExpirationTime * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `HSBC-AUTH-PAGE-LANGUAGE='${languageId}';${expires}`;
  }

}
