import { Component, OnInit } from '@angular/core';
import { RedirectService } from '../redirect.service';
import { JwtService } from '../../shared/jwt.service';

@Component({
    selector: 'app-incorrect-jwt',
    templateUrl: './incorrect-jwt.component.html',
    styleUrls: ['./incorrect-jwt.component.scss', '../../flows/SGH/shared/sgh-flows.scss']
})
export class IncorrectJwtComponent implements OnInit {

    thirdPartyName: string;

    constructor(private redirectService: RedirectService, private jwtService: JwtService) {
    }

    redirect() {
        this.redirectService.returnToThirdParty();
    }

    ngOnInit() {
        this.thirdPartyName = this.jwtService.getThirdPartyName();
    }

}
