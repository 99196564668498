<div class="overview-container" tabindex="0" role="tabpanel" aria-labelledby="helpTab0">
    <img src="../../../../../../../assets/images/one.png" alt="Security device" />
    <div class="overview-content">
        <h2>
            Instructions for use
        </h2>
        <ul>
            <li>
                <div class="sub-title">
                    <!-- <div class="btn-circle">
                        <div class="yellow-rect"></div>
                    </div> -->
                    <img class="btn-img" src="../../../../../../../assets/images/yellow_btn.png"
                        alt="Yellow button (square bottom left with raised surround)">
                    <h3>Yellow button<span class="sr-only">(square buttom left with raised
                        surround)</span></h3>
                </div>
                <ul>
                    <li>
                        <div class="point"></div>
                        <p>This button is used after creating a new PIN</p>
                    </li>
                    <li>
                        <div class="point"></div>
                        <p>The yellow button is also used to generate a transaction code for authorising new payments
                        </p>
                    </li>
                </ul>
            </li>
            <li>
                <div class="sub-title">
                    <img class="btn-img" src="../../../../../../../assets/images/green_btn.png"
                        alt="Green button (circle bottom right with raised surround)">
                    <h3>Green button<span class="sr-only">(circle buttom right with raised
                            surround)</span></h3>
                </div>
                <ul>
                    <li>
                        <div class="point"></div>
                        <p>Press and hold for 2 seconds to turn on the device</p>
                    </li>
                    <li>
                        <div class="point"></div>
                        <p>Press once to delete one character</p>
                    </li>
                    <li>
                        <div class="point"></div>
                        <p>Press and hold to clear all input</p>
                    </li>
                    <li>
                        <div class="point"></div>
                        <p>After you've entered your PIN, press here to generate a log on security code</p>
                    </li>
                </ul>
            </li>
            <li>
                <div class="sub-title">
                    <!-- <div class="btn-circle">
                        <div class="btn-text">3</div>
                    </div> -->
                    <img class="btn-img three_btn" src="../../../../../../../assets/images/3_btn.png" alt="Button 3">
                    <h3>Button 3</h3>
                </div>
                <ul>
                    <li>
                        <div class="point"></div>
                        <p>Press once to generate a reauthentication security code to perform transactions.</p>
                    </li>
                </ul>
            </li>
        </ul>

    </div>
</div>