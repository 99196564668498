import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';
import { buildSghRedirectUri, getSghFirstUriDelimiter } from './utils';
import { environment } from '../../environments/environment';
import { SghConsentsService } from '../flows/SGH/shared/sgh-consents.service';
import { isNoEligibleAccounts } from '../flows/SGH/shared/common/sgh-flow-utils/sgh-flow-utils';
import { Modal, DDModal } from './enums';
import { DDModalService } from '../flows/SGH/shared/common/modal/dd-modal.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectService {

    constructor(private jwtService: JwtService, private consentService: SghConsentsService, private DDModalService: DDModalService) {
    }

    public returnToThirdParty() {
        if (this.jwtService.isEdda) {
            this.consentService.confirmConsent(false, false).subscribe(data => {
                const redirectUri = data['body']['redirect_uri'];
                window.location.replace(redirectUri);
            }, (error) => {
                this.DDModalService.openModal(DDModal.SERVICE_UNAVAILABLE);
            });
        } else {
            const tppUri = this.jwtService.redirectUri || environment.redirectUri;
            // Although GovTech spec does not put error parameter in the error URL, it is requried in OAuth standard https://tools.ietf.org/html/rfc6749#section-4.1.2.1
            const redirectUri = `${tppUri}${getSghFirstUriDelimiter(tppUri)}error=access_denied&respCode=401&respMessage=Authentication%20failure&state=${this.jwtService.state}`;
            window.location.replace(redirectUri);
        }
    }

    initCloseWindowEvent() {
        if (this.jwtService.isEdda) {
            window.addEventListener('beforeunload', () => {
                if (!this.jwtService.isSubmitEdda) {
                    this.consentService.closeWindowPutConsent(false, false);
                    this.sleep(2000);
                }
            });
        }
    }

    sleep(numberMillis) {	
        let now = new Date();	
        let exitTime = now.getTime() + numberMillis;	
        while (true) {	
            now = new Date();	
            if (now.getTime() > exitTime)	
                return;	
        }	
    }
}
