export const environment = {
  production: false,
  qaOne: false,
  qaTwo: false,
  qaThree: false,
  preprod: false,
  supportingAPIUrl: '',
  api: {},
  redirectUri: 'https://stg.fpdsapim.myinfo.gov.sg/consumer/v1/callback-bank',
  isEdda: true,
  balancesThirdPartyName: 'SGFinDex',
  eddaThirdPartyName: 'GIRO'
};
