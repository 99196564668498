import { Injectable } from "@angular/core";
import * as jwt_decode from "jwt-decode";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.edda-dev";

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  jwt: string;
  decodedJwt: any;
  tppName: string;
  redirectUri: string;
  consentStatus: string;
  consentType: string;
  consentSubType: string;
  authorized: string;
  standingOrder: boolean;
  scheduledPayment: boolean;
  isPaymentInternational: boolean;
  brand: string;
  state: string;
  fundsConfirmation: boolean;
  refreshFlow: boolean;
  scope: string;
  isEdda: boolean;
  isSubmitEdda: boolean = false;

  constructor(private http: HttpClient) {

  }

  decodeJwt() {
    try {
      this.decodedJwt = jwt_decode(this.jwt);
    } catch (err) {
      console.log(err);
      return;
    }
    const decoded = this.decodedJwt;
    this.tppName = decoded.tppName;
    this.redirectUri = decoded.redirectUri;
    this.consentStatus = decoded.consentStatus;
    this.consentType = decoded.consentType;
    this.consentSubType = decoded.consentSubType;
    this.standingOrder = decoded.consentSubType ? decoded.consentSubType.includes('StandingOrder') : false;
    this.scheduledPayment = decoded.consentSubType ? decoded.consentSubType.includes('ScheduledPayment') : false;
    this.fundsConfirmation = decoded.consentSubType ? decoded.consentSubType.includes('FundsConfirmation') : false;
    this.isPaymentInternational = decoded.consentSubType ? decoded.consentSubType.includes('International') : false;
    this.refreshFlow = decoded.consentStatus === 'Authorised';
    this.brand = decoded.brand.toLowerCase();
    this.state = decoded.state;
    this.scope = decoded.scope;
    this.isEdda = this.consentType === 'DD';
  }

  initializeTimer() {
    const endDate = this.decodedJwt.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    const timeLeft: number = endDate - currentTime;
  }

  getJwt() {
    if (window.location.search) {
      const redirectJwt = window.location.search.split('redirectJwt=')[1];
      this.jwt = redirectJwt && redirectJwt.split('&')[0];
      this.decodeJwt();
    } else {
      return;
    }
  }

  getThirdPartyName() {
    if (this.isEdda) {
      return environment.eddaThirdPartyName;
    } else {
      return environment.balancesThirdPartyName;
    }
  }
}
