import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {JwtService} from "./jwt.service";

@Injectable({
  providedIn: 'root'
})
export class KeepAliveService {

  constructor(private http: HttpClient, private jwtService: JwtService) {
  }

  keepAlive() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${this.jwtService.jwt}`
    });
    const url = `${environment.supportingAPIUrl || ''}/customer-auth/keep-alive`;
    return this.http.post(url, {}, {headers});
  }
}
