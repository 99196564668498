<div id="{{identification}}-wrapper" class="lightbox-wrapper" aria-modal="true" role="dialog" aria-labelledby="dialogTitle"
     aria-describedby="dialogDesc" #dialog (keydown.escape)="closeLightbox()" tabindex="-1" [class.center] = 'isCenter'>
  <div class="lightbox-content" [class.wide]="isWide"  [class.toolTips]='isToolTips' [class.toolTip-username] = 'isUserName'
       (click)="$event.stopPropagation()"
       [style.maxWidth]="maxWidth+'px'">
    <i id="abc" tabindex="0" aria-label="Close modal button" class="icon icon-close close-sign"
       *ngIf="closable && xButton" (click)="closeLightbox()" (keydown.enter)="closeLightbox()"></i>
    <ng-content></ng-content>
  </div>
</div>
