import { Injectable } from '@angular/core';
@Injectable()
export class TealiumUtagService {

    // Typically set 'noview' flag (no first page automatic view event) to true for Single Page Apps (SPAs)
    constructor() {
        (window as any).utag_cfg_ovrd = { noview: true };
    }

    // Data layer is optional set of key/value pairs
    track(tealiumEvent: string, data?: any) {
        try {
            (window as any).utag.track(tealiumEvent, data);
        } catch (error) {}
    }

    view(data?: any) {
        this.track('view', data);
    }

    link(data?: any) {
        this.track('link', data);
    }
}
