import {Component, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, OnInit} from '@angular/core';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent {

    @Input() label: string;
    @Input() className: string;
    @Input() placeholder: string;

    @Input() inputMode: string = 'text';
    @Input() inputType: string;
    @Input() validationFunction: Function;
    @Input() showHintMark: boolean;
    @Input() maxLength: number;
    @Input() pattern: RegExp;
    @Input() autocomplete: boolean;
    @Input() errorMessage: string;
    @Input() inputError: boolean;
    @Input() customLabelStyles: any;
    @Input() customInputStyles: any;
    @Input() controlledInput: boolean;
    @Input() inputValue: string;

    @Input() ariaLabelValue: string;
    @Input() ariaRequired: boolean;
    @Input() idValue: string = 'input';

    @Output() keyupEvent: EventEmitter<KeyboardEvent> = new EventEmitter();
    @Output() keydownEvent: EventEmitter<KeyboardEvent> = new EventEmitter();
    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter();
    @Output() validationEvent: EventEmitter<any> = new EventEmitter();
    @Output() showHintEvent: EventEmitter<null> = new EventEmitter();
    @Output() enterKeyUpEvent: EventEmitter<KeyboardEvent> = new EventEmitter();
    @Output() blurEvent: EventEmitter<FocusEvent> = new EventEmitter();

    @ViewChild('input', {read: ElementRef}) inputElement: ElementRef;

    private _value: string;
    @Input() set value(value: any) {
        this._value = String(value).toString();
    }

    get value(): any {
        return this._value;
    }

    constructor() {
    }


    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterViewInit() {
        if (this._value != null) {
            this.inputElement['nativeElement'].value = this._value;
        }
    }

    @HostListener('input', ['$event'])
    onInput(event) {
        let data = event.data || event.key;
        if (this.controlledInput) {
            if (data != 'Tab') {
                event.preventDefault();
            }
            return;
        }
        if (this.pattern) {
            if (!data.match(this.pattern)) {
                event.preventDefault();
                return;
            }
        }
    }

    onFocus(event: FocusEvent) {
        this.focus.emit(event);
    }

    clearInput() {
        this.inputElement['nativeElement'].value = '';
    }

    focusOnInput() {
        this.inputElement['nativeElement'].focus();
    }

    onKeyUp(event: KeyboardEvent) {
        this.keyupEvent.emit(event);
        if (this.validationFunction) {
            const inputValue: string = event.target['value'];
            const validationResult = this.validationFunction(inputValue);
            this.validationEvent.emit(validationResult);
        }
    }

    onBlur(event: FocusEvent) {
        this.blurEvent.emit(event);
    }

    showHint() {
        this.showHintEvent.emit();
    }

    enterKeyUp(keyupEvent: KeyboardEvent) {
        this.enterKeyUpEvent.emit(keyupEvent);
    }

}
