<i class="icon icon-circle-help-solid" tabindex="0" *ngIf="showHintMark" (click)="showHint()" role="tooltip"
   aria-describedby="label"></i>
<input #input autocomplete="{{autocomplete ? 'on': 'off'}}" type={{inputType}} [attr.inputmode]="inputMode" id="{{idValue}}"
       placeholder="{{placeholder}}" [maxLength]="maxLength ? maxLength : 524288"
       [attr.aria-label]="ariaLabelValue"
       [attr.aria-required]="ariaRequired"
       (keyup)="onKeyUp($event)" class={{className}}
       (keyup.enter)="enterKeyUp($event)" (focus)="onFocus($event)" [class.error]="errorMessage || inputError"
       [ngStyle]="customInputStyles" tabindex="0" (blur)="onBlur($event)" value={{inputValue}}>
<p class="error-message-box" *ngIf="errorMessage">{{errorMessage}}</p>


