import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoaderComponent} from "./loader.component";
import {SpinnerModule} from "../spinner/spinner.module";

@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule,
    SpinnerModule
  ],
  exports: [LoaderComponent]
})
export class LoaderModule {
}
