import {Component, HostListener, NgZone, OnInit} from '@angular/core';
import {InitializerService} from './initializer.service';
import {Router} from '@angular/router';
import {JwtService} from './shared/jwt.service';
import {ErrorService} from './shared/error.service';
import {SghConsentsService} from './flows/SGH/shared/sgh-consents.service';
import {AppTimeServices} from './shared/appTime.service';
import {BrowserFavicons} from './shared/favicon/favicons';
import {ModalService} from './flows/SGH/shared/common/modal/modal.service';
import {Modal} from './shared/enums';
import {DDModalService} from './flows/SGH/shared/common/modal/dd-modal.service';
import {DDModal} from './shared/enums';
import {TealiumUtagService} from './shared/tealium/utag.service';

@Component({
    selector: 'app-root',
    providers: [TealiumUtagService],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    brand: string;
    standard: string;
    showLoader: boolean;
    title = 'customer-auth-ui';
    consentService;

    constructor(private initializer: InitializerService,
                private jwtService: JwtService,
                private errorService: ErrorService,
                private router: Router,
                private sghConsentService: SghConsentsService,
                private appTime: AppTimeServices,
                private ngZone: NgZone,
                private favIcons: BrowserFavicons,
                private modalService: ModalService,
                private DDModalService: DDModalService){
    }

    setTimeWarnings() {
        this.appTime.showRedirectMessage.subscribe(response => {
            this.ngZone.run(() => {
                if (response) {
                    this.jwtService.isEdda?
                    this.DDModalService.openModal(DDModal.REDIRECTION_TO_TPP):
                    this.modalService.openModal(Modal.REDIRECTION_TO_TPP);
                }
            });
        });

        this.appTime.showLastTimeWarning.subscribe(response => {
            this.ngZone.run(() => {
                if (response) {
                    this.jwtService.isEdda?
                    this.DDModalService.openModal(DDModal.LAST_TIMEOUT_WARNING):
                    this.modalService.openModal(Modal.LAST_TIMEOUT_WARNING);
                }
            });
        });

        this.appTime.showInactivityModal.subscribe(response => {
            this.ngZone.run(() => {
                if (response) {
                    this.jwtService.isEdda?
                    this.DDModalService.openModal(DDModal.TIMEOUT_WARNING):
                    this.modalService.openModal(Modal.TIMEOUT_WARNING);
                }
            });
        });

    }

    @HostListener('window:mousemove')
    @HostListener('window:mousedown')
    @HostListener('window:keydown')
    @HostListener('window:scroll')
    @HostListener('window:touchstart')

    resetInactivityOnUserEvent() {
        this.appTime.inactivity = 0;
    }

    ngOnInit() {
        if ('NodeList' in window && !NodeList.prototype['forEach']) {
            NodeList.prototype['forEach'] = function (callback, thisArg) {
                thisArg = thisArg || window;
                for (var i = 0; i < this.length; i++) {
                    callback.call(thisArg, this[i], i, this);
                }
            };
        }
        this.standard = 'mas';
        if (this.errorService.error) {
            this.router.navigateByUrl('authorize_failure', {skipLocationChange: true});
        } else if (this.jwtService.decodedJwt) {
            this.brand = this.jwtService.brand;
            this.favIcons.init(this.brand);
            this.standard = this.jwtService.decodedJwt.standard.toLowerCase();
            if (this.standard === 'mas') {
                this.consentService = this.sghConsentService;
            }
            // this.consentService.getConsentDetails().subscribe(
            //   (consentDetails: CanonicalConsent & CanonicalDomesticRequest) => {
            //     this.consentService.consentDetails = consentDetails;
            //     if (this.initializer.brandName !== 'hsbc' && this.initializer.brandName !== 'firstdirect' && this.initializer.brandName !== 'marksandspencer') {
            //       this.initializer.brandName = 'hsbc';
            //     }
            this.router.navigateByUrl(`${this.initializer.brandName}_${this.initializer.flowName}`, {skipLocationChange: true});
            this.setTimeWarnings();
            //   },
            //   () => {
            //     this.router.navigateByUrl('consent_fetch_failure', {skipLocationChange: true});
            //   }
            // );
        } else {
            this.router.navigateByUrl('incorrect_jwt', {skipLocationChange: true});
        }
    }
}
