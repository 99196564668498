import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AuthorizeFailureComponent} from "./authorize-failure.component";
import {LightboxModule} from "../lightbox/lightbox.module";
import {ButtonModule} from "../button/button.module";

@NgModule({
  declarations: [AuthorizeFailureComponent],
  imports: [
    CommonModule,
    LightboxModule,
    ButtonModule
  ],
  exports: [AuthorizeFailureComponent]
})
export class AuthorizeFailureModule {
}
