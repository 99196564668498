import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IncorrectJwtComponent} from "./incorrect-jwt.component";
import {LightboxModule} from "../lightbox/lightbox.module";
import {ButtonModule} from "../button/button.module";

@NgModule({
  declarations: [IncorrectJwtComponent],
  imports: [
    CommonModule,
    LightboxModule,
    ButtonModule
  ],
  exports: [IncorrectJwtComponent]
})
export class IncorrectJwtModule {
}
