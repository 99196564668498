import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Input() showLoader: boolean;
  @Input() size: number;
  @Input() stripeWidth: boolean;
  @Input() theme: boolean;

  constructor() {
  }

}
