<app-lightbox [modalOutside]="true">
    <div class="instructions-box">
        <h2 class="h2-modal" id="dialogTitle">Service unavailable</h2>
        <div id="dialogDesc" class="description">
            <p class="text">Something went wrong. We're sorry, this service is temporarily unavailable. Please try again later.</p>
        </div>
        <div class="button-box button-box-long-btn">
            <hr aria-hidden="true">
            <div btn [label]="'Return to ' + thirdPartyName" [className]="'red'" (onClick)="redirect()"></div>
        </div>
    </div>
</app-lightbox>
