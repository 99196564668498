<div class="input-search-group" (blur)="isShowTips=false">
    <div class="input-box input-width">
        <i class="icon icon-find"></i>
        <input #input [(ngModel)]="value" (focus)="isShowTips=true" (change)="isShowTips=true" role="combobox"
            [attr.aria-haspopup]="isShowTips" [attr.aria-expanded]="isShowTips"
            [attr.aria-activedescendant]="chooseError"  aria-autocomplete="list"/>
        <i class="icon icon-close " (click)="clearValue()" (keydown.enter)="clearValue()" (keydown.space)="clearValue()" style="height: 12px;width: 12px" tabindex="0" role="button" aria-label="Clear"></i>
    </div>
    <div *ngIf="(ErrorArrays | appInputFilter:value) as result">
        <ul class="list-group input-width" *ngIf="isShowTips && result.length > 0 " (focus)="isShowTips=true"
            role="listbox" aria-label="Security device message List">
            <li class="list-group-item" *ngFor="let item of result; index as i" role="option"
                [attr.aria-selected]="chooseError == item" [id]="'option'+i">
                <a href="javascript:;" (click)="choiceErrorItem(item)">{{item.title}}</a>
            </li>
        </ul>
        <label class="error m-b input-width no-result-label" *ngIf="result.length == 0" role="alert"
            aria-live="assertive">There are
            no
            results that match your search criteria. Please
            review your request and try again.</label>
    </div>

</div>