import {Injectable, NgZone} from "@angular/core";
import {BehaviorSubject, Subscription} from "rxjs";
import {KeepAliveService} from "./keepAlive.service";
import {options} from "./appConfig";
import {filter, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppTimeServices {

  appTime = new BehaviorSubject(0);
  inactivity: number = 0;
  finalRedirectTime: number = 0;
  redirectionURL: any;
  showInactivityModal = new BehaviorSubject(false);
  showRedirectMessage = new BehaviorSubject(false);
  showLastTimeWarning = new BehaviorSubject(false);
  showLoader = new BehaviorSubject(false);
  timeLeftToRedirect = new BehaviorSubject(0);
  canStayLonger = new BehaviorSubject(true);
  timeToRedirect: number = options.timeToRedirectOnFirstPage;
  firstPageTimeToRedirect: number = options.timeToRedirectOnFirstPage;
  isFirstPage: boolean = true;

  keepAliveCallLastTime: number = 0;

  timer: number = Math.floor(Date.now() / 1000);

  redirectCD: Subscription;

  constructor(private ngZone: NgZone, private keepAlive: KeepAliveService) {
  }

  startLocalTime(redirectionURL) {
    this.redirectionURL = redirectionURL;
    this.ngZone.runOutsideAngular(() => {
      window.setInterval(() => {
        this.timer = this.timer + 1;
        this.appTime.next(this.timer);
      }, 1000);
    });
    this.inactivityChecker();
  }

  resetInactivity() {
    if (!this.isFirstPage) {
      this.showLoader.next(true);
      this.keepAlive.keepAlive().subscribe(() => {
          this.closeModals();
          this.inactivity = 0;
          this.keepAliveCallLastTime = 0;
        },
        (err) => {
          console.log(`failed to fetch keepAlive ${err}`)
        }
      )
    } else {
      this.closeModals();
      this.inactivity = 0;
    }
  }

  closeModals() {
    this.showInactivityModal.next(false);
    this.showRedirectMessage.next(false);
    this.showLastTimeWarning.next(false);
    this.showLoader.next(false);
  }

  switchPages() {
    this.timeToRedirect = options.timeToRedirectOnOtherPages;
    this.isFirstPage = false;
    this.redirectAfterLoginTimeOut();
  }

  redirectAfterLoginTimeOut() {
    this._setTimeOut(() => {
      this.redirectToTPPAfterSessionTimeOut(options.timeBeforeToShowFinalRedirectWarning);
    }, options.timeToRedirectAfterLogin - options.timeBeforeToShowFinalRedirectWarning)
  }

  inactivityChecker() {
    const inactivity = this.appTime.subscribe(() => {
      this.inactivity += 1;
      let leftTime = this.timeToRedirect - this.inactivity;
      if(!this.isFirstPage){
        let dspCallInProgress: boolean = false;
        if (!dspCallInProgress && this.keepAliveCallLastTime >= options.timePeriodToCallKeepAlive) {
          dspCallInProgress = true;
          const reset = () => {
            this.keepAliveCallLastTime = 0;
            dspCallInProgress = false;
          };
          this.keepAlive.keepAlive().subscribe(reset, reset);
        }
        if (leftTime === options.timeBeforeToShowRedirectWarning) {
          this.showRedirectModal(options.timeBeforeToShowRedirectWarning, true);
          inactivity.unsubscribe()
        }  else if (leftTime <= 0) {
          this.redirectToTppNoMessage();
          inactivity.unsubscribe();
        }
      }else {
        this.firstPageTimeToRedirect -=1;
        if (this.firstPageTimeToRedirect <= 0){
          this.redirectToTppNoMessage();
          inactivity.unsubscribe();
        }
      }
    })
  }

  showRedirectModal(countdown: number, canStayLonger: boolean) {

    this.showInactivityModal.next(true);
    this.canStayLonger.next(canStayLonger);

    this.redirectCD = this.appTime.subscribe(() => {
          if(countdown  >= 0){
            this.timeLeftToRedirect.next(countdown)
          } else if(countdown <= 0){
            this.showInactivityModal.next(false);
            this.redirectToTppNoMessage();
            this.redirectCD.unsubscribe();
          }
      countdown -=  1;
    });
  }


  getAppTime() {
    return this.timer;
  }

  _setTimeOut = (fn: any, sec: number, unsubscribe?: boolean) => {
    const endTime = this.timer + sec;
    let setTimer = this.appTime.subscribe((currentTime) => {
      if(!this.isFirstPage){
        this.keepAliveCallLastTime += 1;
      }
      if (currentTime >= endTime) {
        setTimer.unsubscribe();
        if (unsubscribe) return fn.unsubscribe();
        return fn();
      }
    });
  }

  _setInterval = (fn, sec: number) => {
    let timeInterval = 1;
    const interval = this.appTime.subscribe(() => {
      timeInterval = timeInterval + 1;
      if (timeInterval % sec === 0) {
        return fn();
      }
    });
    return interval;
  }

  finalTime(jwtTimeOut: number, redirectURL: string) {
    //const lastFiveMinutes = jwtTimeOut - (options.timeToRedirectOnOtherPages - options.timeBeforeToShowRedirectWarning);
    const lastFiveMinutes = jwtTimeOut - options.timeToRedirectOnOtherPages;
    this.appTime.pipe(filter(appTime => appTime >= lastFiveMinutes), take(1)).subscribe(appTime => {
      //if (!this.isFirstPage) {
        this.showLastTimeWarning.next(true);
      //}
    });

    this.appTime.pipe(filter(appTime => appTime >= jwtTimeOut), take(1)).subscribe(appTime => {
      if (!this.isFirstPage) {
        this.redirectToTPP(10);
      } else {
        this.redirectToTPP(5);
      }
    });
  }

  redirectToTPP(sec: number) {
    this.showRedirectMessage.next(true);
    this._setTimeOut(() => {
      window.location = this.redirectionURL;
    }, sec);
  }

  redirectToTPPAfterSessionTimeOut(timeToWarning: number) {
    const finalRedirect = this.appTime.subscribe(() => {
      if(this.finalRedirectTime === 0){
        this.showInactivityModal.next(true);
        this.canStayLonger.next(false);
        this.inactivity = 0; //reset inactivity to protect against case when 4min warring and final redirect modal appears
      }

      if (this.finalRedirectTime >= 0 && this.finalRedirectTime < timeToWarning) {
        this.timeLeftToRedirect.next(timeToWarning - this.finalRedirectTime)
      } else if (this.finalRedirectTime >= timeToWarning) {
        this.showInactivityModal.next(false);
        this.redirectToTppNoMessage();
        finalRedirect.unsubscribe();
      }

      this.finalRedirectTime = this.finalRedirectTime + 1;
    })

  }

  redirectToTppNoMessage() {
    window.location = this.redirectionURL;
  }
}
