import { Component, OnInit } from "@angular/core";
import { environment } from '../../../environments/environment';
import { JwtService } from '../../shared/jwt.service';

@Component({
  selector: 'app-consent-fetch-failure',
  templateUrl: './consent-fetch-failure.component.html',
  styleUrls: ['./consent-fetch-failure.component.scss', '../../flows/SGH/shared/sgh-flows.scss']
})
export class ConsentFetchFailureComponent implements OnInit {

  thirdPartyName: string;

  constructor(private jwtService: JwtService) {
  }

  redirect() {
    window.location.replace(environment.redirectUri);
  }

  ngOnInit() {
    this.thirdPartyName = this.jwtService.getThirdPartyName();
  }

}
