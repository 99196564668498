import { Component, OnInit } from "@angular/core";
import { JwtService } from "../jwt.service";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent implements OnInit {

  thirdPartyName: string;

  constructor(private jwtService: JwtService) {
  }

  redirect() {
    window.location.replace(this.jwtService.redirectUri);
  }

  ngOnInit() {
    this.thirdPartyName = this.jwtService.getThirdPartyName();
  }

}
