<app-lightbox (closeLightboxEvent)="redirect()">
  <div class="instructions-box">
    <h2 class="h2-modal" id="dialogTitle">System error</h2>
    <p class="description" id="dialogDesc">Something went wrong. Please try again later. If the issue persists, please call
      <a href="https://www.hsbc.com.sg/contact/#call" class="link" target="_blank"
         rel="noopener noreferrer">Customer Services</a><i class="icon-new-window"
                                                           style="margin-left: 2px;margin-right: 2px"></i> .</p>
    <div class="button-box">
      <hr aria-hidden="true">
      <div btn [label]="'Return to ' + this.thirdPartyName" [className]="'red'" (click)="redirect()"></div>
    </div>
  </div>

</app-lightbox>
