import { Component, OnInit } from '@angular/core';
import { ErrorService, SgobErrorResponse } from '../error.service';
import { RedirectService } from '../redirect.service';
import { environment } from '../../../environments/environment';
import { JwtService } from '../../shared/jwt.service';

@Component({
    selector: 'app-authorize-failure',
    templateUrl: './authorize-failure.component.html',
    styleUrls: ['./authorize-failure.component.scss', '../../flows/SGH/shared/sgh-flows.scss']
})
export class AuthorizeFailureComponent implements OnInit {

    error: SgobErrorResponse;
    thirdPartyName: string;

    constructor(errorService: ErrorService, private redirectService: RedirectService, private jwtService: JwtService) {
        this.error = errorService.error;
    }

    ngOnInit() {
        this.thirdPartyName = this.jwtService.getThirdPartyName();
    }

    redirect() {
        this.redirectService.returnToThirdParty();
    }

}
