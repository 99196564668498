import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';

@Component({
    selector: 'app-nav-tab',
    templateUrl: './nav-tab.component.html',
    styleUrls: ['./nav-tab.component.scss']
})
export class NavTabComponent implements OnInit {

    constructor() {
    }

    curTab: number;
    private _arr: Array<[number, string]>;

    @Input() set arr(val: Array<[number, string]>) {
        this._arr = val;
        this.curTab = val[0][0];
    }
    @Input() isWidthTab = false;
    @Input() preFix = 'tab';

    get arr() {
        return this._arr;
    }

    @Output() changeCurTabEvent: EventEmitter<number> = new EventEmitter();


    ngOnInit(): void {
    }

    changeTab(tab: number) {
        this.curTab = tab;
        this.changeCurTabEvent.emit(tab);
    }
    taggerFocus(e: KeyboardEvent) {
        e.key === 'ArrowLeft' ? this.focusWithId(this.preFix + '0') : e.key === 'ArrowRight' ? this.focusWithId(this.preFix + '1') : 0;
    }

    focusWithId(id: string) {
        let focusTarget: HTMLElement;
        focusTarget = document.getElementById(id);
        if (focusTarget) {
            focusTarget.focus();
        }
    }

}
