import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HelpSecurityDeviceComponent} from './help-security-device.component';
import {OverviewComponent} from './overview/overview.component';
import {FindFaultComponent} from './find-fault/find-fault.component';
import {InputModule} from '../../../../../shared/input/input.module';
import {ButtonModule} from '../../../../../shared/button/button.module';
import {InputSearchComponent} from './find-fault/input-search/input-search.component';
import {InputSearchPipe} from './find-fault/input-search/input-search.pipe';
import {NavTabModule} from '../nav-tab/nav-tab.module';

@NgModule({
    declarations: [HelpSecurityDeviceComponent, OverviewComponent, FindFaultComponent, InputSearchComponent, InputSearchPipe],
    exports: [
        HelpSecurityDeviceComponent
    ],
    imports: [
        CommonModule,
        InputModule,
        ButtonModule,
        FormsModule,
        NavTabModule
    ]
})
export class HelpSecurityDeviceModule {
}
