import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ErrorItem} from './error-item';
import {string} from 'prop-types';

@Component({
    selector: 'app-find-fault',
    templateUrl: './find-fault.component.html',
    styleUrls: ['./find-fault.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FindFaultComponent implements OnInit {

    isShowDetails = false;

    green_btn_html = '<div class="btn-circle"><div class="green-rect"></div></div>';
    yellow_btn_html = '<div class="btn-circle"><div class="yellow-rect"></div></div>';
    help_btn_html = '<div class="icon icon-help-active" style="width: 5px;height: 5px"></div>';
    errorItems = [
        // tslint:disable-next-line:max-line-length
        new ErrorItem('../../../../../../assets/images/NEW-PIN@2x.png',
            'New Pin',
            'You will need to create a new Security Device PIN. Think of a PIN 6 digits long. ' +
            'You should avoid using sequential or repeated numbers such as 123456 or 111111. ' +
            'Enter this into your Security Device and press the yellow button' + this.yellow_btn_html + '.\n\n' +
            'Note: If you make a mistake entering your PIN, ' +
            'you can use the green button' + this.green_btn_html + 'to delete the last digit you have input.'
        ),
        new ErrorItem('../../../../../../assets/images/PIN-CONF@2x.png',
            'Pin conf',
            'The PIN you are setting up needs to be confirmed. Please re-enter your chosen PIN. '
        ),
        new ErrorItem('../../../../../../assets/images/NEW-PIN-CONF-HSBC@2x.png',
            'New pin conf HSBC',
            'You have successfully set-up your PIN. You can use your Security Device.'
            // tslint:disable-next-line:max-line-length
        ),
        new ErrorItem('../../../../../../assets/images/FAIL-PIN@2x.png',
            'Fail pin',
            'Your confirmation PIN did not match the first PIN entered.\n\n' +
            'Press the yellow button' + this.yellow_btn_html + 'to return to the PIN setup and repeat the steps to set up a PIN.'
        ),
        new ErrorItem('../../../../../../assets/images/NEW-PIN-NOT-SAFE@2x.png',
            'New pin not safe',
            'The new PIN you have entered is not safe and could be guessed. Please enter another PIN.\n\n' +
            'Your PIN can be made up of any numbers as long as they don\'t follow a logical or sequential pattern, ' +
            'for example 123456 or 111111.'
        ),
        new ErrorItem('../../../../../../assets/images/PIN@2x.png',
            'Pin',
            'The Security Device is waiting for you to enter your PIN. The number of dashes shown on the screen, ' +
            'is equal to the number of digits in your PIN.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'HSBC',
            'This is the welcome screen. You will see this once your PIN has been accepted. ' +
            'Press the green button' + this.green_btn_html + 'to generate your security code.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'Pin Fail 1',
            'The PIN entered is incorrect. Press the green button' + this.green_btn_html + 'lightly and quickly - do not hold it down. ' +
            'This will return you to the PIN entry screen.\n\n' +
            'You will then be prompted to input your PIN again for the second time. ' +
            'To protect you from fraud, the Security Device will lock after three incorrect PIN entries.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'Pin Fail 2',
            'The PIN entered is incorrect. Press the green button' + this.green_btn_html + 'lightly and quickly - do not hold it down. ' +
            'This will return you to the PIN entry screen.\n\n' +
            'You will then be prompted to input your PIN for the third time. ' +
            'To protect you from fraud, the Security Device will lock after three incorrect PIN entries.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'PIN Fail 3',
            'The PIN entered is incorrect. Your Security Device is locked and will need to be reset.\n\n ' +
            'Press the green button' + this.green_btn_html + 'lightly and quickly - do not hold it down. You will see LOCK PIN screen.\n'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'LOCK Pin 4635135',
            'If your Security Device is locked it will display a 7 digit code on screen whenever it is turned on.\n\n' +
            'To continue using online banking, you will need to reset your PIN online.\n\n' +
            'To do this, start logging on to online banking and enter your username.\n\n' +
            'When asked for your security code select the help option' + this.help_btn_html + 'and select \'Forgotten your PIN\', ' +
            'follow the on screen instructions and you will be given a PIN Reset Code.\n\n' +
            'Once you have the code, press the green button' + this.green_btn_html + '. You will then be prompted to input the code.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'Lock Pin',
            'Your Security Device is waiting for you to enter your 7 digit PIN reset code.\n\n' +
            'Once you have entered the code, you will be prompted to set up a new PIN.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'Lock Pin Fail 1',
            'The PIN reset code has been entered incorrectly. Press the green button' + this.green_btn_html + '. ' +
            'You will then be prompted to enter the reset code again.\n\n' +
            'You should ensure that you are entering the reset code exactly as it appears on screen.\n\n' +
            'To protect you from fraud, the Security Device will lock after three incorrect entries.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'Lock Pin Fail 2',
            'The PIN reset code has been entered incorrectly. Press the green button' + this.green_btn_html + '. ' +
            'You will then be prompted to enter the reset code again.\n\n' +
            'You should ensure that you are entering the reset code exactly as it appears on screen.\n\n' +
            'To protect you from fraud, the Security Device will lock after three incorrect entries.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'Lock Pin Fail 3',
            'The PIN reset code has been entered incorrectly for a third time. ' +
            'The Security Device is now temporarily locked and you will be unable to use it for approximately 1 hour. ' +
            'This message will remain on the screen of the Security Device until the lock out has expired, ' +
            'then the Device will automatically power off.\n\n' +
            'When the Security Device is turned back on \'LOCK PIN\' and a 7 digit number will be displayed on the screen. ' +
            'Press the green button' + this.green_btn_html + 'and enter your PIN reset code again. You should ensure that you are' +
            ' entering the code exactly as' +
            ' it appears on screen.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'Lock Pin Fail 4',
            'The PIN reset code has been entered incorrectly for a fourth time. ' +
            'The Security Device is now temporarily locked and you will be unable to use it for approximately 1 hour. ' +
            'This message will remain on the screen of the Security Device until the lock out has expired, ' +
            'then the Device will automatically power off.\n\n' +
            'When the Security Device is turned back on \'LOCK PIN\' and a 7 digit number will be displayed on the screen. ' +
            'Press the green button' + this.green_btn_html + ' and enter your PIN reset code again.\n\n' +
            'If you are unsure what your PIN reset code is, you can generate another code. ' +
            'To do this, start logging on to online banking and enter your username. ' +
            'When asked for your security code select the help option' + this.help_btn_html + 'and select \'Forgotten your PIN\', ' +
            'follow the on screen instructions and you will be given a PIN Reset Code.'
        ),
        new ErrorItem('../../../../../../assets/images/one.png',
            'Lock New Pin',
            'The Security Device is unlocked and you need to create a new  PIN. Think of a number 6 digits long. ' +
            'You should avoid using sequential or repeated numbers such as 123456 or 111111. ' +
            'Enter this into your Security Device and press the yellow button' + this.yellow_btn_html + '.\n\n' +
            'Note: If you make a mistake entering your PIN, ' +
            'you can use the green button' + this.green_btn_html + 'to delete the last digit you have input.'
        ),
        new ErrorItem('../../../../../../assets/images/LOCK-PIN-CONF@2x.png',
            'Lock Pin Conf',
            'The PIN you are setting up needs to be confirmed. Please re-enter your chosen PIN.'
        ),
        new ErrorItem('../../../../../../assets/images/LOCK-FAIL-PIN-1@2x.png',
            'Lock Fail Pin',
            'Press the yellow button' + this.yellow_btn_html + 'to return to the PIN setup and repeat the steps to set up a PIN.'
        ),
        new ErrorItem('../../../../../../assets/images/LOCK-NEW-PIN-NOT-SAFE@2x.png',
            'Lock New Pin Not Safe',
            'The new PIN you have entered is not safe and could be guessed. Please enter another PIN.\n\n' +
            'Your PIN can be made up of any numbers as long as they don\'t follow a logical or sequential pattern, ' +
            'for example 123456 or 111111.'
        ),
        new ErrorItem('../../../../../../assets/images/BUTTON@2x.png',
            'Button',
            'One of the buttons on your Security Device has been held down for too long. ' +
            'To save battery power the Security Device will automatically power off.'
        ),

        new ErrorItem('../../../../../../assets/images/BATT-0@2x.png',
            'BATT 0',
            'The battery is running very low. There\'s only minimal power remaining.\n\n' +
            'You are encouraged to replace your existing Security Device to ensure that you can continue to access the full suite of features online. ' +
            'You can order a replacement by sending us a Secure Message. ' +
            'We will mail the replacement to your correspondence address and it should arrive within 10 working days. ' +
            'Alternatively, you may visit your nearest HSBC branch to get a replacement.'
        ),
        new ErrorItem('../../../../../../assets/images/BATT-1@2x.png',
            'BATT 1',
            'The battery is running low. Don\'t worry - there\'s still approximately 1 month of power remaining.\n\n' +
            'You can order a replacement by sending us a Secure Message. ' +
            'We will mail the replacement to your correspondence address and it should arrive within 10 working days.'
        ),
        new ErrorItem('../../../../../../assets/images/BATT-2@2x.png',
            'BATT 2',
            'The battery is running low. Don\'t worry - there\'s still approximately 2 months of power remaining.\n\n' +
            'You can order a replacement by sending us a Secure Message. ' +
            'We will mail the replacement to your correspondence address and it should arrive within 10 working days.'
        ),
        new ErrorItem('../../../../../../assets/images/BATT-3@2x.png',
            'BATT 3',
            'The battery is running low. Don\'t worry - there\'s still approximately 3 months of power remaining.\n\n' +
            'You can order a replacement by sending us a Secure Message. ' +
            'We will mail the replacement to your correspondence address and it should arrive within 10 working days.'
        ),
        new ErrorItem('../../../../../../assets/images/BATT-4@2x.png',
            'BATT 4',
            'The battery is running low. Don\'t worry - there\'s still approximately 4 months of power remaining.\n\n' +
            'You can order a replacement by sending us a Secure Message. ' +
            'We will mail the replacement to your correspondence address and it should arrive within 10 working days.'
        ),
        new ErrorItem('../../../../../../assets/images/BATT-5@2x.png',
            'BATT 5',
            'The battery is running low. Don\'t worry - there\'s still approximately 5 months of power remaining.\n\n' +
            'You can order a replacement by sending us a Secure Message. ' +
            'We will mail the replacement to your correspondence address and it should arrive within 10 working days.'
        ),


    ];

    curItem: ErrorItem;

    constructor() {
    }

    ngOnInit(): void {
    }

    convertImage(title: string) {
        // tslint:disable-next-line:label-position
        const path = '../../../../../../assets/images/';
        const imageName = title.toLocaleUpperCase().replace(/ /g, '-');
        return path + imageName + '@2x.png';
    }


    findOtherErrorCode() {
        this.isShowDetails = false;
    }

    openErrorItemDetails(errorItem: ErrorItem) {
        this.curItem = errorItem;
        this.isShowDetails = true;

    }
}


