import {Injectable} from "@angular/core";
import {CanonicalConsent, CanonicalDomesticRequest} from "./shared/interfaces";
import {JwtService} from "./shared/jwt.service";
import {ErrorService} from "./shared/error.service";
import {AppTimeServices} from "./shared/appTime.service";
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import { RedirectService } from './shared/redirect.service';

@Injectable({
  providedIn: 'root'
})

export class InitializerService {

  flowName: string;
  brandName: string;
  consentDetails: CanonicalConsent & CanonicalDomesticRequest;

  constructor(private jwtService: JwtService, private errorService: ErrorService, private http: HttpClient, private appTime: AppTimeServices,private redirectService:RedirectService) {
  }

  getFlowName() {
    const flowName = `${this.jwtService.decodedJwt.standard}_${this.jwtService.decodedJwt.consentType}`;
    return flowName.toLowerCase();
  }

  setEnvironmentVariables(brandName: string) {
    if (environment.production || environment.qaOne || environment.qaThree) {
      environment.supportingAPIUrl = environment.api[brandName] || '';
    }
  }

  initializeApp() {
    this.jwtService.getJwt();
    this.errorService.getError();
    this.redirectService.initCloseWindowEvent();
    return new Promise((resolve, reject) => {
      if (this.errorService.error) {
        this.flowName = 'authorize_failure';
        resolve();
      } else if (this.jwtService.decodedJwt) {
        this.appTime.startLocalTime(this.jwtService.decodedJwt.redirectUri);
        this.appTime.finalTime(this.jwtService.decodedJwt.exp, this.jwtService.decodedJwt.redirectUri);
        this.flowName = this.getFlowName();
        this.brandName = this.jwtService.decodedJwt.brand.toLowerCase();
        this.setEnvironmentVariables(this.brandName);
          resolve();

      } else {
        this.flowName = 'incorrect_jwt';
        resolve();
      }
    });
  }

}
