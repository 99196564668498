<div class="find-a-fault-container" tabindex="0" role="tabpanel" aria-labelledby="helpTab1">
    <div class="find-a-fault-context-wrapper" *ngIf="!isShowDetails">
        <img src="../../../../../../../assets/images/one.png" alt="Security device"/>
        <div class="find-a-fault-context">
            <p>If something isn't right, your Security Device will tell you. Just tell us the message shown on your Security Device
                and we'll provide you with the answers and what to do.</p>
            <div class="panel">
                <h2>What's your Security Device telling you?</h2>
                <app-input-search (chooseErrorItemEvent)="openErrorItemDetails($event)" [ErrorArrays]="errorItems"></app-input-search>
            </div>
        </div>
    </div>

    <div class="find-a-fault-desc-context" *ngIf="isShowDetails">
        <img [src]="convertImage(curItem.title)"/>
        <div class="find-a-fault-desc">
            <div class="title">{{curItem.title}}</div>
            <div class="desc" [innerHTML]="curItem.desc"></div>
            <div btn [className]="'link chevron'" (click)="findOtherErrorCode()"
                 [label]="'Find another error code?'" [idName]="'rbwm-not-registered'" [isLink]="true">
            </div>
        </div>

    </div>
</div>
