import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AppLoadingComponent} from "./app-loading.component";
import {LightboxModule} from "../lightbox/lightbox.module";
import {SpinnerModule} from "../spinner/spinner.module";

@NgModule({
  declarations: [AppLoadingComponent],
  imports: [
    CommonModule,
    LightboxModule,
    SpinnerModule
  ],
  exports: [AppLoadingComponent]
})
export class AppLoadingModule {
}
