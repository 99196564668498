import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalComponent} from './modal.component';
import {ModalService} from './modal.service';
import {LightboxModule} from '../../../../../shared/lightbox/lightbox.module';
import {ButtonModule} from '../../../../../shared/button/button.module';
import {LoaderModule} from '../../../../../shared/loader/loader.module';
import {HelpSecurityDeviceModule} from '../../rbwm/help-security-device/help-security-device.module';

@NgModule({
    declarations: [ModalComponent],
    exports: [ModalComponent],
    imports: [
        CommonModule,
        LightboxModule,
        ButtonModule,
        LoaderModule,
        HelpSecurityDeviceModule
    ],
    providers: [ModalService]
})
export class ModalModule {
}
