import {Injectable} from "@angular/core";

export type SgobErrorResponse = {
  fisp: string,
  respCode: string,
  respMessage: string,
  respTime: string
};

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  errorMessage: string;
  error: SgobErrorResponse;

  constructor() {

  }

  decodeError() {
    try {
      // base64url decode
      this.error = JSON.parse(atob(this.errorMessage).replace(/-/g, '+').replace(/_/g, '/'));
    } catch (err) {
      this.error = {
        fisp: 'HSBC',
        respCode: '500',
        respMessage: 'We are unable to process your request.',
        respTime: ''
      };
    }
  }

  getError() {
    if (window.location.search) {
      const errorMessage = window.location.search.split('error=')[1];
      this.errorMessage = errorMessage && errorMessage.split('&')[0];
      if (this.errorMessage) {
        this.decodeError();
      }
    } else {
      return;
    }
  }

}
