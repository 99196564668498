import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MandateInitiation } from '../../../shared/interfaces';
import { environment } from '../../../../environments/environment';
import { JwtService } from '../../../shared/jwt.service';

declare var HSBCGLBL: any;
declare function hsbcglblform(): any;

@Injectable({
  providedIn: 'root'
})
export class SghConsentsService {

  mandateInitiation: MandateInitiation;
  consentDetails: { permissions: any, debtorAccount?: any };

  constructor(private http: HttpClient, private jwtService: JwtService) {
  }

  getConsentDetails() {
    const url = environment.supportingAPIUrl || "";
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    return this.http.get<MandateInitiation>(`${url}/customer-auth/data`, { headers });
  }

  isCreation() {
    return this.mandateInitiation.mandateInfo.mandateRequestType.toLowerCase() === 'creation';
  }

  isCancellation() {
    return this.mandateInitiation.mandateInfo.mandateRequestType.toLowerCase() === 'cancellation';
  }

  putConsent(pickedAccount: any, directDebitExpiryDate?: string, debitLimit?: string, currencyCode?: string, startDate?: string) {
    const url = environment.supportingAPIUrl || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });

    this.mandateInitiation.mandateInfo.debtorAccount = {
      identification: {
        other: {
          identification: pickedAccount.scheme.schemeName,
          accountId: pickedAccount.scheme.identification,
          description: pickedAccount.description
        }
      }
    };
    this.mandateInitiation.mandateInfo.currencyCode = currencyCode;

    if (debitLimit != null) {
      this.mandateInitiation.mandateInfo.maxAmount = Number(debitLimit);
    } else {
      this.mandateInitiation.mandateInfo.maxAmount = Number(99999999999);
    }

    this.mandateInitiation.mandateInfo.occurrences = {
      duration: {
        toDt: directDebitExpiryDate,
        frDt: startDate
      }
    };

    return this.http.put(`${url}/customer-auth/consent`, this.mandateInitiation, { headers });
  }

  putPaymentConsent(pickedAccount?: any) {
    const url = environment.supportingAPIUrl || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    const consent = this.consentDetails;
    if (pickedAccount) {
      consent.debtorAccount = {
        schemeName: pickedAccount.schemeName,
        identification: pickedAccount.identification
      };
    }
    return this.http.put(`${url}/customer-auth/consent`, consent, { headers });
  }

  confirmConsent(authorisationStatus: boolean, tandcAccepted: boolean = true) {
    const fraudHeader = this.getFraudHeader();
    const url = environment.supportingAPIUrl || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-HSBC-Jsc-Data': fraudHeader,
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });

    const body = {
      'authorisationStatus': authorisationStatus,
      'tandcAccepted': tandcAccepted
    };

    return this.http.post(`${url}/customer-auth/confirmation`, body, { headers, observe: 'response' });
  }

  closeWindowPutConsent(authorisationStatus: boolean, tandcAccepted: boolean) {
    const fraudHeader = this.getFraudHeader();
    const url = environment.supportingAPIUrl || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-HSBC-Jsc-Data': fraudHeader,
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });

    const body = {
      'authorisationStatus': authorisationStatus,
      'tandcAccepted': tandcAccepted
    };

    return this.http.post(`${url}/customer-auth/confirmation`, body, { headers, observe: 'response' }).toPromise();
  }


  rejectConsent() {
    const fraudHeader = this.getFraudHeader();
    const url = environment.supportingAPIUrl || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-HSBC-Jsc-Data': fraudHeader,
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    const body = {
      'authorisationStatus': false,
      'tandcAccepted': false
    };
    return this.http.post(`${url}/customer-auth/confirmation`, body, { headers, observe: 'response' });
  }

  private getFraudHeader(): string {
    const hsbcForm = HSBCGLBL.hsbcglblform();
    return hsbcForm;
  }

}
