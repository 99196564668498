import {NgModule, ModuleWithProviders} from "@angular/core";
import {TextContentService} from "../text-content.service";

@NgModule({})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [TextContentService]
    };
  }
}
