import { Account } from './interfaces';

let labelType = 'Account';

export const getObieAispSharingDetails = (permissions, accounts) => {
  labelType = getProductNamePermissionLabel(accounts);
  const mappedPermissions = {
    [`Your ${labelType} Details`]: [],
    [`Your Regular Payments`]: [],
    [`Your ${labelType} Transactions`]: [],
    [`Your Statements`]: [],
    [`Your ${labelType.replace('and', '/')} Features and Benefits`]: [],
    [`Contact and party details`]: []
  };

  const sortedMappedPermissions = [];

  const readTransactionsPermissions = {
    ReadTransactionsDebits: false,
    ReadTransactionsCredits: false,
    ReadTransactionsBasic: false,
    ReadTransactionsDetail: false
  };

  //Cutting out the Basic permissions while Detail ones are present

  permissions = permissions.filter((permissionObj) => {
    if (permissionObj.permission.includes('Basic')) {
      const permissionPrefix = permissionObj.permission.slice(0, -5);
      return !permissions.find((permissionObject) => permissionObject.permission === `${permissionPrefix}Detail`);
    }
    return true;
  });

  permissions.forEach((permissionObj) => {
    const permissionName = permissionObj.permission;
    const permissionMappedValue = obieAispPermissions[permissionName];
    const mappedPermissionHeader = permissionMappedValue.header;

    if (!permissionName.includes('ReadTransactions')) {
      mappedPermissions[mappedPermissionHeader].push(permissionMappedValue.bulletPoint);
    } else {
      readTransactionsPermissions[permissionName] = true;
    }
  });

  //Separate mapping for ReadTransaction permissons:

  if (readTransactionsPermissions.ReadTransactionsBasic) {
    if (readTransactionsPermissions.ReadTransactionsDebits) {
      if (readTransactionsPermissions.ReadTransactionsCredits) {
        mappedPermissions[`Your ${labelType} Transactions`].push(obieAispPermissions.ReadAllTransactionsBasic.bulletPoint);
      } else {
        mappedPermissions[`Your ${labelType} Transactions`].push(obieAispPermissions.ReadTransactionsDebits.bulletPoint);
      }
    } else if (readTransactionsPermissions.ReadTransactionsCredits) {
      mappedPermissions[`Your ${labelType} Transactions`].push(obieAispPermissions.ReadTransactionsCredits.bulletPoint);
    }
  } else if (readTransactionsPermissions.ReadTransactionsDetail) {
    if (readTransactionsPermissions.ReadTransactionsDebits) {
      if (readTransactionsPermissions.ReadTransactionsCredits) {
        mappedPermissions[`Your ${labelType} Transactions`].push(obieAispPermissions.ReadAllTransactionsDetail.bulletPoint);
      } else {
        mappedPermissions[`Your ${labelType} Transactions`].push(obieAispPermissions.ReadTransactionsDebitsDetail.bulletPoint);
      }
    } else if (readTransactionsPermissions.ReadTransactionsCredits) {
      mappedPermissions[`Your ${labelType} Transactions`].push(obieAispPermissions.ReadTransactionsCreditsDetail.bulletPoint);
    }
  }

  //Sorting the permissions and bulletpoints

  for (let permissionHeader in mappedPermissions) {
    if (mappedPermissions[permissionHeader].length) {
      mappedPermissions[permissionHeader].sort((a, b) => bulletPointsOrder.indexOf(a) - bulletPointsOrder.indexOf(b));
      sortedMappedPermissions.push(
        {
          header: permissionHeader,
          bulletPoints: mappedPermissions[permissionHeader]
        }
      );
    }
  }

  return sortedMappedPermissions;
};

export const getConsentAccessExpiryDate = (expirationDateTime: string, consentCreationDate: number) => {
  if (!expirationDateTime) {
    return new Date(Date.now() + 7776000000).toString();
  }
  const nowInMs = Date.now();
  const expirationDateTimeInMs = new Date(expirationDateTime).getTime();
  if ((expirationDateTimeInMs - nowInMs) <= 7776000000) {
    return expirationDateTime;
  } else {
    return new Date(consentCreationDate * 1000 + 7776000000).toString();
  }
};

export const getBalance = (balancesObj: { amount: { amount: string }, creditDebitIndicator: string }) => {
  if (!balancesObj) {
    return 'Not available';
  }
  return `${balancesObj.creditDebitIndicator === 'debit' ? '-' : ''}${balancesObj.amount.amount}`;
};

const ObiePermissionsHeaders = {
  YourAccountDetails: `Your ${labelType} Details`,
  YourRegularPayments: `Your Regular Payments`,
  YourAccountTransactions: `Your ${labelType} Transactions`,
  YourStatements: `Your Statements`,
  YourAccountFeaturesAndBenefits: `Your ${labelType.replace('and', '/')} Features and Benefits`,
  ContactAndPartyDetails: `Contact and party details`
};

const bulletPointsOrder = [
  'Any other name by which you refer to this account and/or the currency of the account.',
  'Your account name, number and sort-code',
  'Your account balance',
  'Your long card number',
  'Payee agreements you have set up',
  'Details of Payee agreements you have set up',
  'Your Standing Orders',
  'Details of your Standing Orders',
  'Your Direct Debits',
  'Recurring and future dated payments',
  'Details of recurring and future dated payments',
  'Your incoming transactions',
  'Your outgoing transactions',
  'Details of your incoming transactions',
  'Details of your outgoing transactions',
  'Your transactions',
  'Details of your transactions',
  'Information contained in your statement',
  'Details of information contained in your statement',
  'Product details - fees, charges, interest, benefits/rewards',
  'Offers available on your account',
  'The full legal name(s) of account holder(s)'
];

const obieAispPermissions = {
  ReadAllTransactionsBasic: {
    header: ObiePermissionsHeaders.YourAccountTransactions,
    bulletPoint: 'Your transactions',
  },
  ReadAllTransactionsDetail: {
    header: ObiePermissionsHeaders.YourAccountTransactions,
    bulletPoint: 'Details of your transactions',
  },
  ReadAccountsBasic: {
    header: ObiePermissionsHeaders.YourAccountDetails,
    bulletPoint: 'Any other name by which you refer to this account and/or the currency of the account.',
  },
  ReadTransactionsDetail: {
    header: ObiePermissionsHeaders.YourAccountTransactions,
    bulletPoint: 'Details of your transactions',
  },
  ReadTransactionsDebits: {
    header: ObiePermissionsHeaders.YourAccountTransactions,
    bulletPoint: 'Your outgoing transactions',
  },
  ReadTransactionsDebitsDetail: {
    header: ObiePermissionsHeaders.YourAccountTransactions,
    bulletPoint: 'Details of your outgoing transactions',
  },
  ReadTransactionsCredits: {
    header: ObiePermissionsHeaders.YourAccountTransactions,
    bulletPoint: 'Your incoming transactions',
  },
  ReadTransactionsCreditsDetail: {
    header: ObiePermissionsHeaders.YourAccountTransactions,
    bulletPoint: 'Details of your incoming transactions',
  },
  ReadTransactionsBasic: {
    header: ObiePermissionsHeaders.YourAccountTransactions,
    bulletPoint: 'Your transactions',
  },
  ReadStatementsDetail: {
    header: ObiePermissionsHeaders.YourStatements,
    bulletPoint: 'Details of information contained in your statement',
  },
  ReadStatementsBasic: {
    header: ObiePermissionsHeaders.YourStatements,
    bulletPoint: 'Information contained in your statement',
  },
  ReadStandingOrdersDetail: {
    header: ObiePermissionsHeaders.YourRegularPayments,
    bulletPoint: 'Details of your Standing Orders',
  },
  ReadStandingOrdersBasic: {
    header: ObiePermissionsHeaders.YourRegularPayments,
    bulletPoint: 'Your Standing Orders',
  },
  ReadScheduledPaymentsDetail: {
    header: ObiePermissionsHeaders.YourRegularPayments,
    bulletPoint: labelType === 'Card' ? 'Details of recurring and future dated payments from your card account' : 'Details of recurring and future dated payments',
  },
  ReadScheduledPaymentsBasic: {
    header: ObiePermissionsHeaders.YourRegularPayments,
    bulletPoint: labelType === 'Card' ? 'Recurring and future dated payments from your card account' : 'Recurring and future dated payments',
  },
  ReadProducts: {
    header: ObiePermissionsHeaders.YourAccountFeaturesAndBenefits,
    bulletPoint: 'Product details - fees, charges, interest, benefits/rewards',
  },
  ReadParty: {
    header: ObiePermissionsHeaders.ContactAndPartyDetails,
    bulletPoint: 'The full legal name(s) of account holder(s)',
  },
  ReadPAN: {
    header: ObiePermissionsHeaders.YourAccountDetails,
    bulletPoint: labelType === 'Account' ? null : 'Your card number',
  },
  ReadOffers: {
    header: ObiePermissionsHeaders.YourAccountFeaturesAndBenefits,
    bulletPoint: labelType === 'Account' ? 'Offers available on your account' : 'Offers available on your card account',
  },
  ReadDirectDebits: {
    header: ObiePermissionsHeaders.YourRegularPayments,
    bulletPoint: 'Your Direct Debits',
  },
  ReadBeneficiariesDetail: {
    header: ObiePermissionsHeaders.YourRegularPayments,
    bulletPoint: 'Details of Payee agreements you have set up',
  },
  ReadBeneficiariesBasic: {
    header: ObiePermissionsHeaders.YourRegularPayments,
    bulletPoint: 'Payee agreements you have set up',
  },
  ReadBalances: {
    header: ObiePermissionsHeaders.YourAccountDetails,
    bulletPoint: 'Your account balance',
  },
  ReadAccountsDetail: {
    header: ObiePermissionsHeaders.YourAccountDetails,
    bulletPoint: labelType === 'Card' ? 'Your account name' : 'Your account name, number and sort-code',
  }
};

export const getProductNamePermissionLabel = (accounts: any) => {

  const productNamesWithDuplicate = accounts.map((account: any) => {
    return account.productName;
  });
  const productNames = productNamesWithDuplicate.filter((item, index) => productNamesWithDuplicate.indexOf(item) === index);

  if (!productNames.includes('CreditCard')) {
    return 'Account';
  } else if (productNames.length == 1) {
    return 'Card';
  } else {
    return 'Accounts and Card';
  }
};

export const formatDate = time => {
  var date = new Date(time);
  var monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
  ];

  var day = date.getDate().toString().padStart(2, '0');
  var monthIndex = (date.getMonth() + 1).toString().padStart(2, '0');
  var year = date.getFullYear();

  return year + '-' + monthIndex + '-' + day;
};

export const getChargeBearerDescription = (brand: string, chargeBearer: string) => {
  switch (chargeBearer.toLowerCase()) {
    case 'shared':
      return `I want to pay the ${brand} fees`;
    case 'bornebycreditor':
      return 'I want the payee to pay all the fees';
    case 'bornebydebtor':
      return 'I want to pay all the fees';
    default:
      return 'Not available.';
  }
};

const currencies = {
  'AFN': 'Afghani',
  'EUR': 'Euro',
  'ALL': 'Lek',
  'DZD': 'Algerian Dinar',
  'USD': 'US Dollar',
  'AOA': 'Kwanza',
  'XCD': 'East Caribbean Dollar',
  'ARS': 'Argentine Peso',
  'AMD': 'Armenian Dram',
  'AWG': 'Aruban Florin',
  'AZN': 'Azerbaijan Manat',
  'BSD': 'Bahamian Dollar',
  'BHD': 'Bahraini Dinar',
  'BDT': 'Taka',
  'BBD': 'Barbados Dollar',
  'BYN': 'Belarusian Ruble',
  'BZD': 'Belize Dollar',
  'XOF': 'CFA Franc BCEAO',
  'BMD': 'Bermudian Dollar',
  'BTN': 'Ngultrum',
  'BOB': 'Boliviano',
  'BOV': 'Mvdol',
  'BAM': 'Convertible Mark',
  'BWP': 'Pula',
  'BRL': 'Brazilian Real',
  'BND': 'Brunei Dollar',
  'BGN': 'Bulgarian Lev',
  'BIF': 'Burundi Franc',
  'CVE': 'Cabo Verde Escudo',
  'KHR': 'Riel',
  'XAF': 'CFA Franc BEAC',
  'CAD': 'Canadian Dollar',
  'KYD': 'Cayman Islands Dollar',
  'CLP': 'Chilean Peso',
  'CLF': 'Unidad de Fomento',
  'CNY': 'Yuan Renminbi',
  'COP': 'Colombian Peso',
  'COU': 'Unidad de Valor Real',
  'KMF': 'Comorian Franc ',
  'CDF': 'Congolese Franc',
  'NZD': 'New Zealand Dollar',
  'CRC': 'Costa Rican Colon',
  'HRK': 'Kuna',
  'CUP': 'Cuban Peso',
  'CUC': 'Peso Convertible',
  'CZK': 'Czech Koruna',
  'DJF': 'Djibouti Franc',
  'DOP': 'Dominican Peso',
  'EGP': 'Egyptian Pound',
  'SVC': 'El Salvador Colon',
  'ERN': 'Nakfa',
  'ETB': 'Ethiopian Birr',
  'FKP': 'Falkland Islands Pound',
  'FJD': 'Fiji Dollar',
  'GMD': 'Dalasi',
  'GEL': 'Lari',
  'GHS': 'Ghana Cedi',
  'GIP': 'Gibraltar Pound',
  'DKK': 'Danish Krone',
  'GTQ': 'Quetzal',
  'GNF': 'Guinean Franc',
  'GYD': 'Guyana Dollar',
  'HTG': 'Gourde',
  'HNL': 'Lempira',
  'HKD': 'Hong Kong Dollar',
  'HUF': 'Forint',
  'ISK': 'Iceland Krona',
  'INR': 'Indian Rupee',
  'IDR': 'Rupiah',
  'XDR': 'SDR (Special Drawing Right)',
  'IRR': 'Iranian Rial',
  'IQD': 'Iraqi Dinar',
  'ILS': 'New Israeli Sheqel',
  'JMD': 'Jamaican Dollar',
  'JPY': 'Yen',
  'GBP': 'Pound Sterling',
  'JOD': 'Jordanian Dinar',
  'KZT': 'Tenge',
  'KES': 'Kenyan Shilling',
  'KPW': 'North Korean Won',
  'KRW': 'Won',
  'KWD': 'Kuwaiti Dinar',
  'KGS': 'Som',
  'LAK': 'Lao Kip',
  'LBP': 'Lebanese Pound',
  'LSL': 'Loti',
  'LRD': 'Liberian Dollar',
  'LYD': 'Libyan Dinar',
  'MOP': 'Pataca',
  'MKD': 'Denar',
  'MGA': 'Malagasy Ariary',
  'MWK': 'Malawi Kwacha',
  'MYR': 'Malaysian Ringgit',
  'MVR': 'Rufiyaa',
  'MRU': 'Ouguiya',
  'MUR': 'Mauritius Rupee',
  'XUA': 'ADB Unit of Account',
  'MXN': 'Mexican Peso',
  'MXV': 'Mexican Unidad de Inversion (UDI)',
  'MDL': 'Moldovan Leu',
  'MNT': 'Tugrik',
  'MAD': 'Moroccan Dirham',
  'MZN': 'Mozambique Metical',
  'MMK': 'Kyat',
  'NAD': 'Namibia Dollar',
  'NPR': 'Nepalese Rupee',
  'NIO': 'Cordoba Oro',
  'NGN': 'Naira',
  'OMR': 'Rial Omani',
  'PKR': 'Pakistan Rupee',
  'PAB': 'Balboa',
  'PGK': 'Kina',
  'PYG': 'Guarani',
  'PEN': 'Sol',
  'PHP': 'Philippine Peso',
  'PLN': 'Zloty',
  'QAR': 'Qatari Rial',
  'RON': 'Romanian Leu',
  'RUB': 'Russian Ruble',
  'RWF': 'Rwanda Franc',
  'SHP': 'Saint Helena Pound',
  'WST': 'Tala',
  'STN': 'Dobra',
  'SAR': 'Saudi Riyal',
  'RSD': 'Serbian Dinar',
  'SCR': 'Seychelles Rupee',
  'SLL': 'Leone',
  'SGD': 'Singapore Dollar',
  'ANG': 'Netherlands Antillean Guilder',
  'XSU': 'Sucre',
  'SBD': 'Solomon Islands Dollar',
  'SOS': 'Somali Shilling',
  'ZAR': 'Rand',
  'SSP': 'South Sudanese Pound',
  'LKR': 'Sri Lanka Rupee',
  'SDG': 'Sudanese Pound',
  'SRD': 'Surinam Dollar',
  'NOK': 'Norwegian Krone',
  'SZL': 'Lilangeni',
  'SEK': 'Swedish Krona',
  'CHF': 'Swiss Franc',
  'CHE': 'WIR Euro',
  'CHW': 'WIR Franc',
  'SYP': 'Syrian Pound',
  'TWD': 'New Taiwan Dollar',
  'TJS': 'Somoni',
  'TZS': 'Tanzanian Shilling',
  'THB': 'Baht',
  'TOP': 'Pa’anga',
  'TTD': 'Trinidad and Tobago Dollar',
  'TND': 'Tunisian Dinar',
  'TRY': 'Turkish Lira',
  'TMT': 'Turkmenistan New Manat',
  'AUD': 'Australian Dollar',
  'UGX': 'Uganda Shilling',
  'UAH': 'Hryvnia',
  'AED': 'UAE Dirham',
  'USN': 'US Dollar (Next day)',
  'UYU': 'Peso Uruguayo',
  'UYI': 'Uruguay Peso en Unidades Indexadas (UI)',
  'UYW': 'Unidad Previsional',
  'UZS': 'Uzbekistan Sum',
  'VUV': 'Vatu',
  'VES': 'Bolívar Soberano',
  'VND': 'Dong',
  'XPF': 'CFP Franc',
  'YER': 'Yemeni Rial',
  'ZMW': 'Zambian Kwacha',
  'ZWL': 'Zimbabwe Dollar',
  'XBA': 'Bond Markets Unit European Composite Unit (EURCO)',
  'XBB': 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
  'XBC': 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
  'XBD': 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
  'XTS': 'Codes specifically reserved for testing purposes',
  'XXX': 'The codes assigned for transactions where no currency is involved',
  'XAU': 'Gold',
  'XPD': 'Palladium',
  'XPT': 'Platinum',
  'XAG': 'Silver'
};

export const countryCodes = {
  'AF': 'Afghanistan',
  'AX': 'Aland Islands',
  'AL': 'Albania',
  'DZ': 'Algeria',
  'AS': 'American Samoa',
  'AD': 'Andorra',
  'AO': 'Angola',
  'AI': 'Anguilla',
  'AQ': 'Antarctica',
  'AG': 'Antigua and Barbuda',
  'AR': 'Argentina',
  'AM': 'Armenia',
  'AW': 'Aruba',
  'AU': 'Australia',
  'AT': 'Austria',
  'AZ': 'Azerbaijan',
  'BS': 'Bahamas',
  'BH': 'Bahrain',
  'BD': 'Bangladesh',
  'BB': 'Barbados',
  'BE': 'Belgium',
  'BY': 'Belarus',
  'BZ': 'Belize',
  'BJ': 'Benin',
  'BM': 'Bermuda',
  'BT': 'Bhutan',
  'BO': 'Bolivia',
  'BA': 'Bosnia and Herzegovina',
  'BW': 'Botswana',
  'BQ': 'Bonaire',
  'BV': 'Bouvet Island',
  'BR': 'Brazil',
  'IO': 'British Indian Ocean Territory',
  'BN': 'Brunei Darussalam',
  'BG': 'Bulgaria',
  'BF': 'Burkina Faso',
  'BI': 'Burundi',
  'KH': 'Cambodia',
  'CM': 'Cameroon',
  'CA': 'Canada',
  'CV': 'Cape Verde',
  'KY': 'Cayman Islands',
  'CF': 'Central African Republic',
  'TD': 'Chad',
  'CL': 'Chile',
  'CN': 'China',
  'CX': 'Christmas Island',
  'CC': 'Cocos (Keeling) Islands',
  'CO': 'Colombia',
  'KM': 'Comoros',
  'CG': 'Congo',
  'CD': 'Congo, Democratic Republic of',
  'CK': 'Cook Islands',
  'CR': 'Costa Rica',
  'CI': 'Cote D\'ivoire',
  'HR': 'Croatia',
  'CU': 'Cuba',
  'CW': 'Curacao',
  'CY': 'Cyprus',
  'CZ': 'Czech Republic',
  'DK': 'Denmark',
  'DJ': 'Djibouti',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'EC': 'Ecuador',
  'EG': 'Egypt',
  'SV': 'El Salvador',
  'GQ': 'Equatorial Guinea',
  'ER': 'Eritrea',
  'EE': 'Estonia',
  'ET': 'Ethiopia',
  'FK': 'Falkland Islands',
  'FO': 'Faroe Islands',
  'FJ': 'Fiji',
  'FI': 'Finland',
  'FR': 'France',
  'GF': 'French Guiana',
  'PF': 'French Polynesia',
  'TF': 'French Southern Territories',
  'GA': 'Gabon',
  'GM': 'Gambia',
  'GE': 'Georgia',
  'DE': 'Germany',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GR': 'Greece',
  'GL': 'Greenland',
  'GD': 'Grenada',
  'GP': 'Guadeloupe',
  'GU': 'Guam',
  'GT': 'Guatemala',
  'GG': 'Guernsey',
  'GN': 'Guinea',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HT': 'Haiti',
  'HM': 'Heard Island and McDonald Islands',
  'HN': 'Honduras',
  'HK': 'Hong Kong',
  'HU': 'Hungary',
  'IS': 'Iceland',
  'IN': 'India',
  'ID': 'Indonesia',
  'IQ': 'Iraq',
  'IE': 'Ireland',
  'IM': 'Isle of Man',
  'IL': 'Israel',
  'IT': 'Italy',
  'JM': 'Jamaica',
  'JP': 'Japan',
  'JE': 'Jersey',
  'JO': 'Jordan',
  'KZ': 'Kazakhstan',
  'KE': 'Kenya',
  'KI': 'Kiribati',
  'KR': 'Korea, South',
  'KW': 'Kuwait',
  'KG': 'Kyrgyzstan',
  'LA': 'Laos',
  'LV': 'Latvia',
  'LB': 'Lebanon',
  'LS': 'Lesotho',
  'LR': 'Liberia',
  'LY': 'Libya',
  'LI': 'Liechtenstein',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'MO': 'Macau',
  'MK': 'Macedonia',
  'MG': 'Madagascar',
  'MW': 'Malawi',
  'MY': 'Malaysia',
  'MV': 'Maldives',
  'ML': 'Mali',
  'MT': 'Malta',
  'MH': 'Marshall Islands',
  'MQ': 'Martinique',
  'MR': 'Mauritania',
  'MU': 'Mauritius',
  'YT': 'Mayotte',
  'MX': 'Mexico',
  'FM': 'Micronesia',
  'MD': 'Moldova',
  'MC': 'Monaco',
  'MN': 'Mongolia',
  'ME': 'Montenegro',
  'MS': 'Montserrat',
  'MA': 'Morocco',
  'MZ': 'Mozambique',
  'MM': 'Myanmar',
  'NA': 'Namibia',
  'NR': 'Nauru',
  'NP': 'Nepal',
  'NL': 'Netherlands',
  'NC': 'New Caledonia',
  'NZ': 'New Zealand',
  'NI': 'Nicaragua',
  'NE': 'Niger',
  'NG': 'Nigeria',
  'NU': 'Niue',
  'NF': 'Norfolk Island',
  'MP': 'Northern Mariana Islands',
  'NO': 'Norway',
  'OM': 'Oman',
  'PK': 'Pakistan',
  'PW': 'Palau',
  'PS': 'Palestinian Territory, Occupied',
  'PA': 'Panama',
  'PG': 'Papua New Guinea',
  'PY': 'Paraguay',
  'PE': 'Peru',
  'PH': 'Philippines',
  'PN': 'Pitcairn',
  'PL': 'Poland',
  'PT': 'Portugal',
  'PR': 'Puerto Rico',
  'QA': 'Qatar',
  'RE': 'Reunion',
  'RO': 'Romania',
  'RU': 'Russia',
  'RW': 'Rwanda',
  'BL': 'Saint Barthelemy',
  'SH': 'Saint Helena',
  'KN': 'Saint Kitts and Nevis',
  'LC': 'Saint Lucia',
  'PM': 'Saint Pierre and Miquelon',
  'VC': 'Saint Vincent and the Grenadines',
  'WS': 'Samoa',
  'SM': 'San Marino',
  'ST': 'Sao Tome and Principe',
  'SA': 'Saudi Arabia',
  'SN': 'Senegal',
  'RS': 'Serbia',
  'SC': 'Seychelles',
  'SL': 'Sierra Leone',
  'SG': 'Singapore',
  'SK': 'Slovakia',
  'SI': 'Slovenia',
  'SB': 'Solomon Islands',
  'SO': 'Somalia',
  'ZA': 'South Africa',
  'GS': 'South Georgia and Sandwich Islands',
  'ES': 'Spain',
  'LK': 'Sri Lanka',
  'MF': 'St-Martin',
  'SR': 'Suriname',
  'SJ': 'Svalbard and Jan Mayen',
  'SZ': 'Swaziland',
  'SE': 'Sweden',
  'CH': 'Switzerland',
  'TW': 'Taiwan',
  'TJ': 'Tajikistan',
  'TZ': 'Tanzania, United Republic of',
  'TH': 'Thailand',
  'TL': 'Timor-Leste',
  'TG': 'Togo',
  'TK': 'Tokelau',
  'TO': 'Tonga',
  'TT': 'Trinidad and Tobago',
  'TN': 'Tunisia',
  'TR': 'Turkey',
  'TM': 'Turkmenistan',
  'TC': 'Turks and Caicos Islands',
  'TV': 'Tuvalu',
  'UG': 'Uganda',
  'UA': 'Ukraine',
  'AE': 'United Arab Emirates',
  'GB': 'United Kingdom',
  'US': 'United States',
  'UM': 'United States Minor Outlying Islands',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VU': 'Vanuatu',
  'VA': 'Vatican City',
  'VE': 'Venezuela',
  'VN': 'Vietnam',
  'VG': 'Virgin Islands, British',
  'VI': 'Virgin Islands, U.S.',
  'WF': 'Wallis and Futuna',
  'EH': 'Western Sahara',
  'YE': 'Yemen',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
  'SD': 'Sudan'
};

export const getCurrencyFullName = (currency: string) => {
  return currencies[currency] || 'N/A';
};

export const getFormattedSortCode = (accountNumber: string) => {
  return `${accountNumber.slice(0, 2)}-${accountNumber.slice(2, 4)}-${accountNumber.slice(4, 6)}`;
};

export const getFirstUriDelimiter = (uri: any): string => {
  return String(uri).includes('#') ? '&' : '#';
};

export const buildObieRedirectUri = (response: any) => {
  return new RedirectUrlStringBuilder(`${response.body.redirect_uri}${getFirstUriDelimiter(response.body.redirect_uri)}code=${response.body.auth_code}`)
    .withState(response.body.state)
    .withIdToken(response.body.id_token)
    .build();
};

export const getSghFirstUriDelimiter = (uri: any): string => {
  return String(uri).includes('?') ? '&' : '?';
};

export const buildSghRedirectUri = (response: any) => {
  return new RedirectUrlStringBuilder(`${response.body.redirect_uri}${getSghFirstUriDelimiter(response.body.redirect_uri)}code=${response.body.auth_code}`)
    .withState(response.body.state)
    .withIdToken(response.body.id_token)
    .build();
};

export const checkIfMobileIos = () => {
  return !!navigator.platform && /iPad|iPhone|iPod|Android/.test(navigator.platform);
};

export const formatFrequency = (frequency: string) => {

  if (frequency.startsWith('IntrvlMnthDay:01:3') || frequency.startsWith('IntrvlMnthDay:01:29') || frequency.startsWith('IntrvlMnthDay:01:28')) {
    return 'Last working day of the month';
  }

  if (frequency.startsWith('IntrvlMnthDay:01')) {
    return 'Monthly';
  }

  if (frequency.startsWith('IntrvlMnthDay:03')) {
    return 'Quarterly';
  }

  if (frequency.startsWith('IntrvlMnthDay:12')) {
    return 'Annually';
  }

  if (frequency.startsWith('IntrvlWkDay:01')) {
    return 'Weekly';
  }

  if (frequency.startsWith('IntrvlWkDay:02')) {
    return 'Fortnightly';
  }

  if (frequency.startsWith('IntrvlWkDay:04')) {
    return 'Every 4 weeks';
  }

  return frequency;
};

export const appendDialogInsideComponent = (dialogId) => {
  const dialogParent = document.getElementById(dialogId);
  const dialogEl = document.getElementById(`${dialogId}-wrapper`);

  if (dialogParent) {
    dialogParent.appendChild(dialogEl);
  }
};

class RedirectUrlStringBuilder {

  redirectUri: string;

  constructor(redirectUri: string) {
    this.redirectUri = redirectUri;
  }

  withState(state) {
    if (state) {
      this.redirectUri = this.redirectUri.concat('&state=', state);
    }
    return this;
  }

  withIdToken(idToken) {
    if (idToken) {
      this.redirectUri = this.redirectUri.concat('&id_token=', idToken);
    }
    return this;
  }

  build() {
    return this.redirectUri;
  }

}
