import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import * as createFocusTrap from 'focus-trap/dist/focus-trap.js';

declare const document: any;

@Component({
    selector: 'app-lightbox',
    templateUrl: './lightbox.component.html',
    styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() maxWidth: number;
    @Input() closable: boolean = true;
    @Input() xButton: boolean;
    @Input() lastActivatedElement: string;
    @Input() focusElement: string;
    @Input() modalOutside: boolean;
    @Input() identification: string;

    @Output() closeLightboxEvent: EventEmitter<null> = new EventEmitter();
    @ViewChild('dialog') dialog: ElementRef;
    @Input() isToolTips: Boolean = false;
    @Input() isWide: Boolean = false;
    @Input() isCenter: Boolean = false;
    @Input() isUserName: Boolean = false;

    focusTrap: any;

    constructor() {
    }

    ngOnInit() {
        const allInputElement = document.getElementsByTagName('input');
        const errorMessage = document.querySelector('.error-selector');
        for (let i = 0; i < allInputElement.length; i++) {
            if (allInputElement[i]) {
                allInputElement[i].blur();
            }
        }
        if (errorMessage) {
            errorMessage.blur();
        }
    }

    closeLightbox() {
        if (this.closable) {
            this.closeLightboxEvent.emit();
        }
    }

    focusToLastElement() {
        if (this.lastActivatedElement) {
            setTimeout(() => {
                document.getElementById(this.lastActivatedElement).focus();
            }, 100);
        }
    }

    ngOnDestroy() {
        const mainWrapper = document.querySelector('.content-wrapper');
        this.focusToLastElement();
        if (!this.isMobile()) {
            try {
                this.focusTrap.deactivate();
            } catch (err) { }
        }
        mainWrapper.removeAttribute('aria-hidden');
    }

    ngAfterViewInit() {
        const dialogEl = document.getElementById(`${this.identification}-wrapper`);

        const mainWrapper = document.querySelector('.content-wrapper');
        const timeout = this.isMobile() ? 1000 : 200;
        // temp flag changes for modal keeping outside journey wrapper
        if (this.modalOutside) {
            document.body.appendChild(dialogEl);
            mainWrapper.setAttribute('aria-hidden', 'true');
        }
        if (document.getElementById('abc')) {
            document.getElementById('abc').focus();
        }

        setTimeout(() => {
            if (this.isMobile() || document.documentMode || /Edge/.test(navigator.userAgent)) {
                setTimeout(() => {
                    dialogEl.focus();
                }, 500);
            } else if (this.focusElement) {
                setTimeout(() => {
                    document.getElementById(this.focusElement).focus();
                }, 100);
            } else {
                setTimeout(() => {
                    dialogEl.focus();
                }, 100);
            }

            if (!this.isMobile()) {
                this.focusTrap = createFocusTrap(this.dialog.nativeElement);
                try {
                    this.focusTrap.activate();
                } catch (err) { }
            }
        }, timeout);
    }

    isMobile() {
        const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return true;
        }

        if (/android/i.test(userAgent)) {
            return true;
        }

        if (/iPhone|iPad|iPod/.test(userAgent) && !window['MSStream']) {
            return true;
        }

        return false;
    }
}
