import {Component, OnInit, Input} from '@angular/core';

declare const document: any;

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() size: number;
  @Input() stripeWidth: number;
  @Input() theme: string;

  constructor() { }

  ngOnInit() {
    if (document.documentMode || /Edge/.test(navigator.userAgent)) {
      const spinner = document.getElementById('loading-spinner');
      spinner.setAttribute('tabIndex', '0');
      spinner.focus();

      spinner.addEventListener('blur', function () {
        spinner.removeAttribute('tabIndex');
      });
    }
  }

}
