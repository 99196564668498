import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DDModalComponent} from './dd-modal.component';
import {DDModalService} from './dd-modal.service';
import {LightboxModule} from '../../../../../shared/lightbox/lightbox.module';
import {ButtonModule} from '../../../../../shared/button/button.module';
import {LoaderModule} from '../../../../../shared/loader/loader.module';
import {HelpSecurityDeviceModule} from '../../rbwm/help-security-device/help-security-device.module';

@NgModule({
    declarations: [DDModalComponent],
    exports: [DDModalComponent],
    imports: [
        CommonModule,
        LightboxModule,
        ButtonModule,
        LoaderModule,
        HelpSecurityDeviceModule
    ],
    providers: [DDModalService]
})
export class DDModalModule {
}
