import { Component, NgZone, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DDModal } from '../../../../../shared/enums';
import { DDModalService } from './dd-modal.service';
import { SghConsentsService } from '../../sgh-consents.service';
import { JwtService } from '../../../../../shared/jwt.service';
import { appendDialogInsideComponent} from '../../../../../shared/utils';
import { AppTimeServices } from '../../../../../shared/appTime.service';
import { TextContentService } from '../../../../../shared/text-content.service';
import { RedirectService } from '../../../../../shared/redirect.service';

@Component({
  selector: 'dd-app-modal',
  templateUrl: './dd-modal.component.html',
  styleUrls: ['./modal.component.scss', '../../sgh-flows.scss']
})
export class DDModalComponent implements OnInit {

  openedModals: any;
  showLoader: boolean;
  tppName: string;
  isDevice: boolean;
  brandId: string;
  refreshFlow: boolean;
  isStandingOrder: boolean;
  isPaymentInternational: boolean;
  sec: number;
  canStayLonger: boolean;
  additionalWording: string;
  textContent;

  constructor(private modalService: DDModalService, private consentService: SghConsentsService, private deviceService: DeviceDetectorService, private jwtService: JwtService,
    private appTime: AppTimeServices, private ngZone: NgZone,
    private contentService: TextContentService,
    private redirectService: RedirectService) {
    this.openedModals = {};
  }

  returnToThirdParty() {
    this.showLoader = false;
    this.redirectService.returnToThirdParty();
  }

  closeModal(dialogId: string) {
    document.body.classList.remove('overflow-y-hidden');
    appendDialogInsideComponent(dialogId);
    this.openedModals[dialogId] = false;
  }

  handleHttpError() {
    this.showLoader = false;
    this.modalService.openModal(DDModal.SERVICE_UNAVAILABLE);
  }

  getPrimaryButtonClassName() {
    switch (this.jwtService.brand) {
      case 'hsbc':
        return 'red';
      case 'marksandspencer':
        return 'mns-primary';
      case 'firstdirect':
        return 'fd-primary';
      default:
        return 'red';
    }
  }

  getSecondaryButtonClassName() {
    switch (this.jwtService.brand) {
      case 'hsbc':
        return 'white';
      case 'marksandspencer':
        return 'mns-white';
      case 'firstdirect':
        return 'fd-secondary';
      default:
        return 'white';
    }
  }

  stayLonger(dialogId: string) {
    this.appTime.inactivityChecker();
    if (this.appTime.redirectCD) {
      this.appTime.redirectCD.unsubscribe();
    }
    this.closeModal(dialogId);
  }

  ngOnInit() {
    this.textContent = this.contentService.textContent;
    this.contentService.changeLanguageEvent.subscribe(() => {
      this.textContent = this.contentService.textContent;
    });
    this.modalService.action.subscribe((modalName: DDModal) => {
      document.body.classList.add('overflow-y-hidden');
      // if (this.currentModal && this.currentModal !== Modal.NONE) {
      //   this.closeModal(this.currentModal);
      // }
      this.openedModals[modalName] = true;

    });
    this.appTime.timeLeftToRedirect.subscribe(response => {
      this.ngZone.run(() => {
        this.sec = response;
      });
    });
    this.appTime.canStayLonger.subscribe(response => {
      this.ngZone.run(() => {
        this.canStayLonger = response;
      });
    });
    this.canStayLonger = true;
    this.refreshFlow = this.jwtService.refreshFlow;
    this.tppName = this.jwtService.tppName;
    this.isDevice = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.isStandingOrder = this.jwtService.standingOrder;
    this.isPaymentInternational = this.jwtService.isPaymentInternational;
    switch (this.jwtService.brand) {
      case 'hsbc':
        this.brandId = 'rb';
        break;
      case 'marksandspencer':
        this.brandId = 'mns';
        break;
      case 'firstdirect':
        this.brandId = 'fd';
        break;
      default:
        this.brandId = 'rb';
    }
  }

}
