import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Modal} from '../../../../../shared/enums';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  action: Subject<Modal> = new Subject();
  additionalWording: Subject<string> = new Subject();

  openModalWithWording(modalName: Modal, additionalWording: string) {
    this.action.next(modalName);
    this.additionalWording.next(additionalWording);
  }

  openModal(modalName: Modal) {
    this.openModalWithWording(modalName, null);
  }

  constructor() {
  }
}
