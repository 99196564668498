import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-app-loading',
  templateUrl: './app-loading.component.html',
  styleUrls: ['./app-loading.component.scss']
})
export class AppLoadingComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
