import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-help-security-device',
    templateUrl: './help-security-device.component.html',
    styleUrls: ['./help-security-device.component.scss']
})
export class HelpSecurityDeviceComponent implements OnInit {

    overview: [number, string] = [0, 'Overview'];
    findFault: [number, string] = [1, 'Find a fault'];
    curTab = this.overview[0];
    tabArray: Array<[number, string]> = [this.overview, this.findFault];

    constructor() {
    }

    ngOnInit(): void {
    }

    changeCurTab(curTab: number) {
        this.curTab = curTab;
    }
}
