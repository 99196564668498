import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ConsentFetchFailureComponent} from "./consent-fetch-failure.component";
import {LightboxModule} from "../lightbox/lightbox.module";
import {ButtonModule} from "../button/button.module";

@NgModule({
  declarations: [ConsentFetchFailureComponent],
  imports: [
    CommonModule,
    LightboxModule,
    ButtonModule
  ],
  exports: [ConsentFetchFailureComponent]
})
export class ConsentFetchFailureModule {
}
