import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {TextContentService} from './shared/text-content.service';
import {InitializerService} from './initializer.service';
import {Route, RouterModule} from '@angular/router';
import {SharedModule} from './shared/shared/shared.module';
import {IncorrectJwtComponent} from './shared/incorrect-jwt/incorrect-jwt.component';
import {AuthorizeFailureComponent} from './shared/authorize-failure/authorize-failure.component';
import {ServiceUnavailableModule} from './shared/service-unavailable/service-unavailable.module';
import {IncorrectJwtModule} from './shared/incorrect-jwt/incorrect-jwt.module';
import {AuthorizeFailureModule} from './shared/authorize-failure/authorize-failure.module';
import {AppLoadingComponent} from './shared/app-loading/app-loading.component';
import {AppLoadingModule} from './shared/app-loading/app-loading.module';
import {ConsentFetchFailureComponent} from './shared/consent-fetch-failure/consent-fetch-failure.component';
import {ConsentFetchFailureModule} from './shared/consent-fetch-failure/consent-fetch-failure.module';
import {LightboxModule} from './shared/lightbox/lightbox.module';
import {ButtonModule} from './shared/button/button.module';
import {AppTimeServices} from './shared/appTime.service';
import {FAVICONS_CONFIG, BrowserFavicons} from './shared/favicon/favicons';
import {LoaderModule} from './shared/loader/loader.module';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {ModalModule} from './flows/SGH/shared/common/modal/modal.module';
import {DDModalModule} from './flows/SGH/shared/common/modal/dd-modal.module';

// import { NavTabComponent } from './flows/SGH/shared/rbwm/nav-tab/nav-tab.component';

export function setupTranslateFactory(service: TextContentService) {
    return () => service.getTextContent();
}

export function setupInitializeFactory(service: InitializerService) {
    return () => service.initializeApp();
}

const routes: Route[] = [
    {
        path: '',
        component: AppLoadingComponent
    },
    {
        path: 'service_unavailable',
        component: ConsentFetchFailureComponent
    },
    {
        path: 'consent_fetch_failure',
        component: ConsentFetchFailureComponent
    },
    {
        path: 'hsbc_mas_aisp',
        loadChildren: () => import('./flows/SGH/rbwm/AISP/sgh-aisp-account-selection/sgh-aisp-account-selection.module').then(m => m.SghAispAccountSelectionModule)
    },
    {
        path: 'hsbc_mas_dd',
        loadChildren: () => import('./flows/SGH/rbwm/DD/sgh-aisp-account-selection/sgh-dd-account-selection.module').then(m => m.SghDdAccountSelectionModule)
    },
    {
        path: 'incorrect_jwt',
        component: IncorrectJwtComponent
    },
    {
        path: 'authorize_failure',
        component: AuthorizeFailureComponent
    },
    {
        path: '**',
        component: AppLoadingComponent
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule.forRoot(routes),
        SharedModule.forRoot(),
        IncorrectJwtModule,
        AuthorizeFailureModule,
        ServiceUnavailableModule,
        AppLoadingModule,
        ConsentFetchFailureModule,
        LightboxModule,
        ButtonModule,
        LoaderModule,
        DeviceDetectorModule.forRoot(),
        ModalModule,
        DDModalModule
    ],
    providers: [
        BrowserFavicons,
        TextContentService,
        InitializerService,
        AppTimeServices,
        {
            provide: APP_INITIALIZER,
            useFactory: setupTranslateFactory,
            deps: [TextContentService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: setupInitializeFactory,
            deps: [InitializerService],
            multi: true
        },
        {
            provide: FAVICONS_CONFIG,
            useValue: {
                imageType: 'image/png',
                assetPath: './assets/images/',
                iconSuffix: '-fav-icon.png',
                cacheBusting: true
            }
        }
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
